<h1 mat-dialog-title>{{ data.label }}</h1>
<mat-dialog-content>
  <p>{{ data.instructions }}
  </p>
  <mat-form-field appearance="outline" class="flex-fill">
    <textarea matInput matNativeControl [(ngModel)]="data.message" rows="10" ></textarea>
  </mat-form-field>
  <div *ngIf="error"> <mat-error>{{error}}</mat-error></div>
  <div *ngIf="message">{{message}}</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close >Cancel</button>
  <button mat-flat-button color="accent" mat-dialog-close (click)="contactus()">Contact Us</button>
</mat-dialog-actions>
