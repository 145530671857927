<h1 mat-dialog-title>{{ data.dialogHeader }}</h1>
<mat-dialog-content>
  <h2>Contact Information</h2>
  <mat-form-field class="flex-fill" appearance="outline" required >
    <mat-label>First Name</mat-label>
    <input matInput [(ngModel)]="data.user.first_name" #first_name="ngModel" required >
    <mat-error *ngIf="first_name.invalid && (first_name.dirty || first_name.touched)">First Name is required</mat-error>
  </mat-form-field>

  <mat-form-field class="flex-fill" appearance="outline" required >
    <mat-label>Last Name</mat-label>
    <input matInput [(ngModel)]="data.user.last_name" #last_name="ngModel" required >
    <mat-error *ngIf="last_name.invalid && (last_name.dirty || last_name.touched)">Last Name is required</mat-error>
  </mat-form-field>

  <mat-form-field class="flex-fill" appearance="outline" required >
    <mat-label>Email</mat-label>
    <input matInput [(ngModel)]="data.user.email" rows="3" type="email" #email="ngModel" [ngModelOptions]="{updateOn: 'blur'}"
           required email duplicateEmailAsyncValidator [appUserId]="data.user ? data.user.id : 0">
    <mat-error *ngIf="email.invalid && (email.dirty || email.touched)">
      <ng-container *ngIf="email.hasError('isDuplicate')">Email address already in use</ng-container>
      <ng-container *ngIf="email.hasError('email')">Invalid email address</ng-container>
      <ng-container *ngIf="email.hasError('required')">Email address is required</ng-container>
    </mat-error>
  </mat-form-field>

  <h2>Site Access<span matTooltip="OWNER: Manage users in the organization and edit any content&#13;
    EDITOR: Edit documents in countries to which they have access&#13;
    VIEWER: View documents in countries to which they have access
    "><mat-icon svgIcon="help"></mat-icon></span></h2>
  <mat-form-field class="flex-fill" appearance="outline" required >
    <mat-label>Role</mat-label>
    <mat-select [(ngModel)]="data.user.organization_role" #role="ngModel" required [disabled]="data.currentUser && (data.currentUser.id == data.user.id)"  (ngModelChange)="roleChange($event)">
      <mat-option value="viewer">Viewer</mat-option>
      <mat-option value="editor">Editor</mat-option>
      <mat-option value="owner">Owner</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-radio-group class="flex-fill" [(ngModel)]="data.allCountries" aria-label="Select an option" [disabled]="data.user.organization_role == 'owner'"  (ngModelChange)="countriesChange($event)">
    <mat-radio-button value="all" style="margin-right: 10px;">Access all countries</mat-radio-button>
    <mat-radio-button value="selected">Access only selected countries</mat-radio-button>
  </mat-radio-group>

  <mat-form-field  appearance="outline" class="editmember__countries flex-fill">
    <mat-label>Select Countries</mat-label>
    <mat-select [(ngModel)]="data.user.countries" multiple [compareWith]="compareWithFn" [disabled]="data.allCountries == 'all' || data.user.organization_role == 'owner'" #selectedcountries="ngModel" required>
      <mat-option *ngFor="let country of data.organizationCountries" [value]="country">{{country.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="error"> <mat-error>{{error}}</mat-error></div>
  <div *ngIf="message">{{message}}</div>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button [mat-dialog-close]="false" >Cancel</button>
  <button mat-flat-button color="accent"
          [mat-dialog-close]="data.user"
          [disabled]="first_name.invalid || last_name.invalid || email.invalid || selectedcountries.invalid || role.invalid"
          >Save Changes</button>
</mat-dialog-actions>
