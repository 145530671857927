<div class="preferences__content-wrapper card__wrapper">

  <mat-card appearance="outlined" class="flex-fill">
    <mat-card-title>
      <h1>Update Preferences</h1>
    </mat-card-title>
    <mat-card-content class="updates__history-list-wrapper">

      <div class="preferences__section-main">
        <form (ngSubmit)="updatePreferences()"   >

          <mat-form-field class="flex-fill" appearance="outline">
            <mat-label>How often do you wish to receive notifications of new updates?</mat-label>
            <mat-select name="notification_frequency" [(ngModel)]="notificationFrequency" #role="ngModel">
              <mat-option value="weekly">Weekly</mat-option>
              <mat-option value="monthly">Monthly</mat-option>
              <mat-option value="never">Never</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="preferences__button-wrapper">
            <a mat-flat-button color="accent" (click)="updatePreferences()" type="submit" class="submit-button">Update Preferences</a>
          </div>
          <div *ngIf="error" class="preferences__error-wrapper">
            <mat-error><div >{{error}}</div></mat-error>
          </div>
          <div *ngIf="message">{{message}}</div>
        </form>
      </div>

    </mat-card-content>
  </mat-card>
</div>
