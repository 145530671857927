<div class="compare__content-wrapper card__wrapper">
  <mat-card appearance="outlined" >
    <mat-card-title class="card__title">
      <h1>Compare</h1>
    </mat-card-title>
    <mat-card-content class="compare__table-wrapper">
      <app-compare-table></app-compare-table>
    </mat-card-content>
  </mat-card>
</div>
