<div class="flex-column" *ngIf="(country$|async)?.calculator">
  <div class="calculator__inputs flex-row-center-center" >
      <mat-form-field class="flex-300px" appearance="outline" floatLabel="always">
        <mat-label>Employee's Salary</mat-label>
        <input matInput #salaryInput
        (ngModelChange)="salary=$event"
        (keyup.enter)="onCalculate()"
        [ngModel]="salary"
        >        
      </mat-form-field>
      <mat-form-field class="flex-200px" appearance="outline">
        <mat-label>Period</mat-label>
        <mat-select [(ngModel)]="salary_period" (selectionChange)="onCalculate()">
          <mat-option value='monthly'>Monthly</mat-option>
          <mat-option value='yearly'>Annual</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field  class="flex-300px" appearance="outline">
        <mat-label>Currency</mat-label>
        <mat-select [(ngModel)]="selectedCurrency" (selectionChange)="onCalculate()">
          <mat-option value='USD'>US Dollar (USD)</mat-option>
          <mat-option value='EUR' *ngIf="(country$|async)?.currency_iso != 'EUR'">Euro (EUR)</mat-option>
          <mat-option value='{{ (country$|async)?.currency_iso }}'>{{ (country$|async)?.currency_name }} ({{ (country$|async)?.currency_iso }})</mat-option>
          <mat-option *ngFor="let c of (currencies$|async)|keyvalue:currencyOrder" [value]="c.key">{{c.value}} ({{c.key}})</mat-option>
        </mat-select>
      </mat-form-field>
  </div>
  <div class="calculator__inputs__actions flex-row-center-center">
    <button mat-flat-button color="accent" (click)='onCalculate()' [disabled]="salary?.trim() == ''">Calculate Employer Costs</button>
  </div>

  <div class="employment-costs__summary_wrapper flex-row-center-center" *ngIf="(cost$|async)" >

      <table class="employment-costs__summary">
          <tr>
              <th>{{ display_period == 'monthly' ? 'Monthly' : 'Yearly' }} Salary</th>
              <td>{{ formatNumber((cost$|async).salary) }} {{ (cost$|async).currency }}</td>
          </tr>
          <tr>
              <th>Total {{ display_period == 'monthly' ? 'Monthly' : 'Yearly' }} Employer Costs</th>
              <td>{{ formatNumber((cost$|async).employer_costs) }} {{ (cost$|async).currency }}</td>
          </tr>
          </table>
      <mat-form-field class="flex-200px" appearance="outline" class="employment-costs__show_period">
        <mat-label>Show as</mat-label>
        <mat-select [(ngModel)]="display_period" (selectionChange)="onCalculate()">
          <mat-option value='monthly'>Monthly</mat-option>
          <mat-option value='yearly'>Annual</mat-option>
        </mat-select>
      </mat-form-field>

  </div>
  <mat-table *ngIf="(cost$ | async)?.cost_details" [dataSource]="(cost$ | async)?.cost_details">
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
      <mat-cell *matCellDef="let cost">{{ cost.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="frequency">
      <mat-header-cell *matHeaderCellDef>Frequency</mat-header-cell>
      <mat-cell  *matCellDef="let cost">{{ cost.frequency }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef>Amount</mat-header-cell>
      <mat-cell *matCellDef="let cost">{{ formatNumber(cost.amount) }} {{ (cost$|async).currency }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="['description', 'frequency', 'amount']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['description', 'frequency', 'amount'];"></mat-row>

  </mat-table>
  
  <p *ngIf="(cost$ | async)?.cost_details" >This payroll calculator provides an estimate only, based on the data you input and current tax rates, mandatory social insurance contribution rates and other country- and region-specific charges. Actual payroll calculations may vary and should be confirmed with a qualified professional.</p>

  <div class="calculator__inputs__actions flex-row-center-center">
      <button *ngIf="(cost$|async)" mat-flat-button color="accent" onClick="window.print()" class="calculator__print" >Print</button>
  </div>



</div>
