<div class="currency__wrapper" *ngIf="(currencies$|async) && working">

  <div class="flex-row-start">
    <mat-form-field class="flex-100px">
      <input matInput
      (ngModelChange)="baseCurrencyAmount=$event"
      [ngModel]="baseCurrencyAmount"
      (input)="onBaseChange()">
    </mat-form-field>
    <div class="currency__base-currency flex-300px">{{(country$|async)?.currency_name}}</div>
  </div>
  <div class="currency__equals">=</div>
  <div class="flex-row-start">
    <mat-form-field class="flex-100px">
      <input matInput
      (ngModelChange)="otherCurrencyAmount=$event"
      [ngModel]="otherCurrencyAmount"
      (input)="onOtherChange()">
    </mat-form-field>

    <mat-form-field class="flex-300px">
      <mat-select [(ngModel)]="otherCurrency" (selectionChange)='onChangeCountry()'>
        <mat-option value='USD'>US Dollar (USD)</mat-option>
        <mat-option value='EUR'>Euro (EUR)</mat-option>
        <mat-option *ngFor="let c of (currencies$|async)|keyvalue:currencyOrder" [value]="c.key">{{c.value}} ({{c.key}})</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
