import { Injectable } from '@angular/core';

import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class RedirectGuard  {

constructor(private router: Router) {}
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const externalUrl = 'externalUrl';
    window.location.href = route.data[externalUrl];
    return true;
  }
}
