<app-country-banner *ngIf="(country$ | async)?.banner" [country$]="country$"></app-country-banner>
<div class="country-profile__content-wrapper">
  <mat-card appearance="outlined">
    <mat-card-title>
      <div class="country-profile__header-wrapper">
        <h1 class="flex"><span *ngIf="(country$ | async)?.name"><a [routerLink]="['/country', (country$ | async)?.slug]" >{{ (country$ | async)?.name }}</a> : Country Profile</span></h1>
        <div class="countryprofile__background-toggle-wrapper">
          <div *ngIf="!documentDoesNotExist">
            <mat-slide-toggle [disableRipple]="true" (change)="toggleBackground($event)">Show Background Information</mat-slide-toggle>
            <div matTooltip="Background information is the GPS-provided universal definition of the employment category being reviewed"
                class="countryprofile__background-what-this" >What is this?</div>
          </div>
        </div>
      </div>
      <button id="back-to-top" class="back-to-top" mat-mini-fab color="primary" aria-label="Back to Top" (click)="backToTop()">
        <mat-icon svgIcon="up" (click)="backToTop()"></mat-icon>
      </button>

      <div class="country-profile__search-bar flex-row-start-space-between">
        <app-country-profile-search [country$]="country$" class="country-profile__search"></app-country-profile-search>
        <div class="flex-row-end country-profile__actions">
          <app-country-profile-download [country$]="country$"></app-country-profile-download>
          <a class="country-profile__print" href="javascript:print();">
            <div class="country-profile__icon-wrapper"><mat-icon svgIcon="print"></mat-icon></div>Print
          </a>
        </div>
      </div>
    </mat-card-title>
    <mat-card-content *ngIf="!documentDoesNotExist; else doesNotExist">
      <div class="countryprofile__document-wrapper ">
        <div   class="countryprofile__section countryprofile__section-left">

          <h2>Table of Contents</h2>
          <app-country-profile-tree [country$]="country$"></app-country-profile-tree>
          <h2>Recent Updates</h2>
          <app-country-updates-as-list [country$]="country$" class="no-padding no-header"></app-country-updates-as-list>

        </div>
        <div class="countryprofile__section flex countryprofile__section-main" id="countryprofile__section-main">
          <app-country-profile-sections [country$]="country$"></app-country-profile-sections>
        </div>
      </div>
    </mat-card-content>
    <ng-template #doesNotExist>
      <mat-card-content>
        <div class="countryprofile__missing">
          <p>Your personalized Country Profile for {{ (country$|async)?.name }} is being updated.<br/>Please check back in a few minutes.</p>
        </div>
      </mat-card-content>
    </ng-template>
  </mat-card>
</div>
