import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UserService } from '../user/user.service';

// Restrict access for pages that only the owner can use
@Injectable()
export class OwnerRoleGuard  {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(private userService: UserService) { }

  canActivate() {
    return this.userService.canManage();
  }
}

