import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import {
  switchMap,
  tap,
  distinctUntilChanged,
  shareReplay,
  take,
  map,
  mergeMap,
  catchError,
  first,
} from 'rxjs/operators';
import { Country } from 'src/app/models/country';
import { CountryService } from 'src/app/services/country/country.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CountrySettings } from 'src/app/models/country-settings';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialog } from 'src/app/util/alert-dialog/alert-dialog';
import { ContactService } from 'src/app/services/contact/contact.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss'],
})
export class CountryComponent implements OnInit {
  private _country: Country;
  public country$: Observable<Country>;
  public isUserCountry$: Observable<boolean>;
  public loading_user_country = true;



  public requestAddToSubscription() {
    if (this._country.unsupported) {
      return this.requestCreateCountry();
    }
    if (this.loading_user_country) {
      return;
    }
    const dialogRef = this.dialog.open(AlertDialog, {
      width: '600px',
      data: {
        title: 'Request access',
        message: ''.concat(
          'This country is not part of your current subscription. To request access to the country profile for ',
          this._country.name,
          ', click the "Submit request" button below.'
        ),
        cancelButtonText: 'Cancel',
        okButtonText: 'Submit request',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.contactService
          .postComment({
            type: 'Request Country from Country Detail Page',
            data: { country: this._country.name },
          })
          .subscribe(
            (data) => {
              const snackBarRef = this.snackBar.open(
                ''.concat(
                  'Your request to add ',
                  this._country.name,
                  '  has been submitted'
                ),
                'Close',
                { duration: 5000 }
              );
            },
            (err) => {
              const snackBarRef = this.snackBar.open(
                'There was a problem sending the message. Please contact support@globalpeoplestrategist.com for assistance',
                'Close'
              );
            }
          );
      }
    });
  }

  public requestCreateCountry() {
    const dialogRef = this.dialog.open(AlertDialog, {
      width: '600px',
      data: {
        title: 'Request country',
        message: ''.concat(
          'This country is currently unavailable. To request access, click the "Submit request" button below.'
        ),
        cancelButtonText: 'Cancel',
        okButtonText: 'Submit request',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.contactService
          .postComment({
            type: 'Request Unavailable Country from Country Detail Page',
            data: { country: this._country.name },
          })
          .subscribe(
            (data) => {
              const snackBarRef = this.snackBar.open(
                ''.concat(
                  'Your request to access ',
                  this._country.name,
                  '  has been submitted'
                ),
                'Close',
                { duration: 5000 }
              );
            },
            (err) => {
              const snackBarRef = this.snackBar.open(
                'There was a problem sending the message. Please contact support@globalpeoplestrategist.com for assistance',
                'Close'
              );
            }
          );
      }
    });
  }

  constructor(
    private route: ActivatedRoute,
    private countryService: CountryService,
    public dialog: MatDialog,
    public contactService: ContactService,
    public snackBar: MatSnackBar,
    private router: Router,
    private mixpanelService: MixpanelService
  ) { }

  ngOnInit() {

    this.country$ = this.route.paramMap.pipe(
      distinctUntilChanged(),
      switchMap((params: ParamMap) =>
        this.countryService.getCountry(params.get('slug'))
      ),
      catchError((_) => {
        this.router.navigate(['404']);
        return of(null);
      }),
      tap((c) => {
        this.isUserCountry$ = this.countryService.isUserCountry(c);
        this.isUserCountry$.pipe(first()).subscribe(() => {
          this.loading_user_country = false;
        });
      }),
      tap((c) => (this._country = c)),
      tap((c) =>
        this.mixpanelService.track('Page: Country', { 'GPS Country': c?.name })
      ),
      shareReplay(1)
    );
  }
}
