<div class="changepassword__content-wrapper">
  <mat-card appearance="outlined" class="flex-fill">
    <mat-card-title>
      <h1>Set Password</h1>
    </mat-card-title>
    <mat-card-content>
      <form (ngSubmit)="sendPasswordResetEmail()" class="flex-row">
        <div class="setpassword__section-main">
          <p>
            Click the button below to send a password reset email to
            <b>{{ user?.email }}</b>
          </p>

          <div>
            <a
              *ngIf="user"
              mat-flat-button
              color="accent"
              (click)="sendPasswordResetEmail()"
              type="submit"
              >Send password reset email</a
            >
          </div>
          <div class="setpassword__message-wrapper">
            <div *ngIf="message">{{ message }}</div>
          </div>
          <div class="setpassword__error-wrapper">
            <mat-error
              ><div *ngIf="error">{{ error }}</div></mat-error
            >
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
