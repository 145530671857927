<h1 mat-dialog-title>Print</h1>
<mat-dialog-content>
    <form mat-form>
        <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker" (click)="picker.open()" >
              <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="changing()">
              <input matEndDate formControlName="end" placeholder="End date" (dateChange)="changing()">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          
            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>          
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
  <button mat-flat-button color="accent" [mat-dialog-close]="data" [disabled]="!validDateRangeSelected">Print</button>
</mat-dialog-actions>
