@import "variables";

.countryprofilesection__wrapper {
  border-bottom: 1px solid #E91E63;
  padding-bottom: 10px;

  &.level-1 {
    .countryprofilesection__title {
      font-weight: 600;
    }
  }
  &.level-3 {
    .countryprofilesection__title {
      text-transform: none;
    }
  }
}

.countryprofilesection__content-wrapper {
  margin-left: 50px;
  padding-right: 40px;
  @media screen and (max-width: 800px) {
    margin-left: 0;
    padding-right: 0;
  }
}

.countryprofilesection__ckeditor-wrapper {
  margin-bottom: 10px;
}

.countryprofilesection__title {
  font-size: 24px;
  line-height: 1.25;
  font-weight: 300;
  text-transform: uppercase;
  color: $text;
}

.countryprofilesection__content {
  font-weight: 300;
  font-size: 18px;
  line-height: 1.5;
  color: $text;
  min-height: 50px;

  /* Generic table styling */
  table {
    border-collapse: collapse;
    margin-bottom: 1em;
    td, th {
      border: 1px solid $border-gray;
      // vertical-align: top;
      margin: 0;
      padding: 10px;
    }
  }
}

.countryprofilesection__content {
  p:first-child {
    margin-top: 0;
  }
  word-wrap: break-word;
}

.countryprofilesection__flag-placeholder {
  float: left;
  width: 32px;
  height: 32px;
  margin-top: 6px;
  background-color: gray;
  @media(max-width: 600px) {
    display: none;
  }
}

.countryprofilesection__flag-image {
  width: 32px;
  height: 32px;
  box-shadow: 0 2px 4px rgba(0,0,0,.5) ;
}

.countryprofilesection__tools {
  margin-bottom: 10px;
}

.countryprofilesection__tool,
.countryprofilesection__title-tool {
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  color: $link-dark;
  font-size: 14px;
  line-height: 2;
  margin-right: 20px;
  position: relative;
}

.countryprofilesection__tool--arrow {
  display: block;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 30px solid #ECEFF1;
  position: absolute;
  left: 45px;
  margin-top: 0px;
  @media screen and (max-width: 800px) {
    display: none;
  }
}

.countryprofilesection__icon-wrapper {
  display: inline-block;
  height: 30px;
  vertical-align: middle;
  margin-right: -5px;
}

.countryprofilesection__tool-content {
  @extend .countryprofilesection__content;
  background-color: #ECEFF1;
  padding: 15px 20px;
}

.countryprofilesection__tool-header {
  font-size: 20px;
  text-transform: uppercase;
  margin: 10px 0;
}

.countryprofilesection__historywrapper {
  padding: 10px 0;
}

.countryprofilesection__historydate {
  font-weight: 600;
}


table.countryprofilesection__holidays {
  border-collapse: collapse;
  margin-bottom: 20px;
}

table.countryprofilesection__holidays th {
  text-align: left;
}

table.countryprofilesection__holidays th,
table.countryprofilesection__holidays td
 {
  border: 1px solid $border-gray;
  vertical-align: top;
  margin: 0;
  padding: 10px;
}

td.countryprofilesection__holidays_date {
  // font-weight: bold;
}

td.countryprofilesection__holidays_description {
  width: 50%;
}

.countryprofilesection__holidays--local td.countryprofilesection__holidays_description {
  width: 40%;
}



/* CKeditor styles */

.ck-editor__editable {
  @extend .countryprofilesection__content;
}

@media print {
  .countryprofilesection__title-tools,
  .countryprofilesection__flag-placeholder,
  .countryprofilesection__tools
  {
    display: none !important;
  }

  .countryprofilesection__content-wrapper {
    margin-left: 0 !important;
  }
}
