<h1 mat-dialog-title>Filter Calendar View</h1>
<mat-dialog-content>
  <form mat-form [formGroup]="form">
  <h2 style="color: black; margin-bottom: 10px;">Topics</h2>

  <mat-checkbox (change)="toggleAllTopics($event)" [formControl]="selectAll" ngDefaultControl><strong>View all topics</strong></mat-checkbox>
  <div style="columns: 2;">
    <ng-container formArrayName="topics">
      <div *ngFor="let topic of data.allTopics; let i = index">
         <mat-checkbox [formControlName]="i" (change)="updateSelectedTopics()">
           {{topic.name}}
         </mat-checkbox>
      </div>
    </ng-container>
    <mat-checkbox (change)="toggleHolidays($event)" [formControl]="showHolidays" ngDefaultControl>Public Holidays</mat-checkbox>
  </div>

  <h2 style="color: black; margin-bottom: 10px;">Countries</h2>
  <div class="country-controls-wrapper">
    <mat-radio-group class="flex-fill" [(ngModel)]="data.viewAllCountries"  [ngModelOptions]="{standalone: true}" aria-label="Select an option" (ngModelChange)="countriesChange($event)" ngDefaultControl>
        <mat-radio-button value="all" style="margin-right: 10px;">View all countries</mat-radio-button>
        <mat-radio-button value="selected">View selected countries</mat-radio-button>
    </mat-radio-group>
    
    <mat-form-field  appearance="outline" class="calendarfilter__countries flex-fill">
        <mat-label>Select Countries</mat-label>
        <mat-select [(ngModel)]="data.selectedCountries" multiple [compareWith]="compareWithFn" [ngModelOptions]="{standalone: true}" [disabled]="data.viewAllCountries === 'all'" #selectedcountries="ngModel" ngDefaultControl required>
          <mat-option *ngFor="let country of data.allCountries" [value]="country">{{country.name}}</mat-option>
        </mat-select>
    </mat-form-field>
  </div>
</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
  <button mat-flat-button color="accent" [mat-dialog-close]="data" cdkFocusInitial>Apply Filters</button>
</mat-dialog-actions>
