<app-country-banner *ngIf="(country$ | async)?.banner" [country$]="country$"></app-country-banner>
<div class="country-snapshot__content-wrapper">
  <mat-card appearance="outlined">
    <mat-card-title>
      <div  class="country-snapshot__header-wrapper card__title">
        <h1 class="flex"><span *ngIf="(country$ | async)?.name"><a [routerLink]="['/country', (country$ | async)?.slug]" >{{ (country$ | async)?.name }}</a><span class="separator"> : </span><span class="country-snapshot__label">Country Snapshot</span></span></h1>
      </div>
    </mat-card-title>
    <mat-card-content>
      <div  class="country-snapshot__search-bar card__title"></div>
      <img *ngIf="(country$ | async)?.iso2" class="country-snapshot__header-image" [src]="'/assets/images/country-snapshot-headers/' + (country$|async)?.iso2.toUpperCase() + '-CPD_Background.png'">
      <app-country-snapshot-sections [country$]="country$"></app-country-snapshot-sections>
      <div class="footer">&copy; Global People Strategist {{ currentYear }} </div>
    </mat-card-content>
  </mat-card>
</div>
