<div class="dashboard__content-wrapper">
  <div class="dashboard__section dashboard__section-main">
    <mat-card appearance="outlined" >
      <mat-card-title class="card__title">
        <h1>My Countries</h1>
        <div>
          <a  mat-flat-button color="accent" [matMenuTriggerFor]="wantToMenu" class="dashboard__wantto">I want to...
            <mat-icon iconPositionEnd class="icon-white" svgIcon="down"></mat-icon>
          </a>
          <mat-menu xPosition="before"  #wantToMenu="matMenu">
            <mat-nav-list class="actionmenu">
              <a href mat-list-item [routerLink]="['/compare']">
                <span matListItemTitle>COMPARE COUNTRIES</span>
                <span matListItemLine>Compare regulations across countries</span>
              </a>
              <app-request-country></app-request-country>
            </mat-nav-list>
          </mat-menu>

        </div>
      </mat-card-title>
      <mat-card-content class="dashboard__country-list-wrapper">
        <app-country-list></app-country-list>
        <a mat-flat-button color="accent" class="dashboard__all-countries-link" href [routerLink]="['/countries']"  *ngIf="!(isNotificationOnly$|async)">View all countries</a>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="dashboard__section dashboard__section-sidebar">
    <app-ai-global></app-ai-global>
    <app-event-calendar *ngIf="(isNotificationOnly$|async) === false"></app-event-calendar>
    <app-promotion-sidebar *ngIf="(isNotificationOnly$|async)"></app-promotion-sidebar>
  </div>
</div>
