<div class="main__toolbar-wrapper">
  <mat-toolbar  class="main__toolbar">
    <a [routerLink]="['/dashboard']" class="main__logo-link"><img src="/assets/images/logo-header.png"/></a>
  </mat-toolbar>
</div>

<div class="content">
  <p>We seem to have a problem</p>
  <a mat-stroked-button [routerLink]="['/dashboard']">Reload</a>
</div>

<mat-toolbar class="main__footer flex-row-start">
  <a href="https://www.globalpeoplestrategist.com/terms-of-use/" target="_blank">Terms of Service</a><span class="main__footer-divider">|</span>
  <a href="https://www.globalpeoplestrategist.com/blog/" target="_blank">Blog</a><span class="main__footer-divider">|</span>
  <a href="mailto:support@globalpeoplestrategist.com">Contact us</a>
</mat-toolbar>
