import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { map, take, tap, mergeMap } from 'rxjs/operators';
import { OrganizationService } from '../organization/organization.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  private env = environment;

  /**
   * Initialize mixpanel.
   */
  init(userToken?: string): void {
    mixpanel.init(this.env.mixpanel_token);
    if (userToken) {
      mixpanel.identify(userToken);
      // Get current user information, but do not redirect to login on failure
      this.userService
        .currentUser(false)
        .pipe(
          map((user) => {
            return user
              ? {
                $name: [user.first_name, user.last_name].join(' '),
                'First Name': user.first_name,
                'Last Name': user.last_name,
                Email: user.email,
                $email: user.email,
                Countries: user.countries.map((c) => c?.name),
                Organization: user.organization_name,
                'Organization Role': user.organization_role,
              }
              : {};
          }),
          take(1)
        )
        .subscribe((props) => {
          if (props) {
            mixpanel.people.set(props);
          }
        });
    }
  }

  /**
   * Push new action to mixpanel.
   *
   * @param Name of the action to track.
   * @param Actions object with custom properties.
   */
  track(id: string, action: any = {}): void {
    try {
      const distinctId = mixpanel.get_distinct_id();
    } catch (e) {
      this.init();
    }

    // Write to Mixpanel
    mixpanel.track(id, action);
    // Call our server to log the event as well
    // TODO: If we can figure out when Mixpanel fails, only do this when it failes
    this.http
      .get(this.env.api_root.concat('headless/activity/', id))
      .subscribe();
  }

  constructor(
    private userService: UserService,
    private router: Router,
    private http: HttpClient
  ) {
    //   DISABLING THIS FUNCTIONALITY SINCE ARE LOGGING EVENTS ON EACH PAGE INDIVIDUALLY
    //   this.router.events.subscribe((e) => {
    //     if (e instanceof NavigationEnd) {
    //         this.track(e.urlAfterRedirects);
    //     }
    //   }
    //  );
  }
}
