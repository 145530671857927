import { HttpParams, HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import Rollbar from 'rollbar';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { Country } from 'src/app/models/country';
import { LibraryDocument } from 'src/app/models/library-document';
import { PaginatedResponse } from 'src/app/models/paginated-response';
import { RollbarService } from 'src/app/util/rollbar';
import { environment } from 'src/environments/environment';
import { ServiceBase } from '../service-base';
import { LibraryDocumentFilter } from 'src/app/models/library-document-filter';
import { LibraryDocumentType } from 'src/app/models/library-document-type';

@Injectable({
  providedIn: 'root',
})
export class LibraryDocumentService implements ServiceBase {
  private env = environment;
  private _libraryDocuments$: BehaviorSubject<LibraryDocument[]>;
  private _countryLibraryDocuments$: Map<
    string,
    BehaviorSubject<LibraryDocument[]>
  > = new Map();

  public getLibraryDocuments(filter: LibraryDocumentFilter): Observable<PaginatedResponse<LibraryDocument>> {
    let params = new HttpParams();
    if (filter.country) {
      params = params.set('country', filter.country);
    }
    if (filter.keyword) {
      params = params.set('keyword', filter.keyword);
    }
    if (filter.type) {
      params = params.set('type', filter.type);
    }
    if (filter.page) {
      params = params.set('page', filter.page.toString());
    }
    if (filter.pageSize) {
      params = params.set('pageSize', filter.pageSize.toString());
    }

    const result$ = this.http.get<PaginatedResponse<LibraryDocument>>(this.env.api_root.concat('headless/library'), {
      params,
    })
      .pipe(
        shareReplay(1),
      )
    return result$;
  }

  public getLibraryDocumentTypes(): Observable<LibraryDocumentType[]> {
    return this.http.get<LibraryDocumentType[]>(this.env.api_root.concat('headless/librarytypes'))
      .pipe(
        shareReplay(1),
      )
  }

  public getAllLibraryDocumentsForCountry(
    country: Country
  ): Observable<LibraryDocument[]> {
    return this.getLibraryDocuments({ country: country.iso2, pageSize: 100 })?.pipe(
      map((response) => response.results)
    );
  }

  public createLibraryDocument(
    country: Country,
    file: File,
    name: string
  ): Observable<object> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('name', name);
    formData.append('country', country.iso2);
    return this.http
      .post(this.env.api_root.concat('headless/organizationlibrary'), formData)
  }

  public deleteLibraryDocument(document: LibraryDocument) {
    return this.http
      .delete(
        this.env.api_root.concat(
          'headless/organizationlibrary/',
          document.id.toString()
        )
      )
  }

  public sortDocuments(a: LibraryDocument, b: LibraryDocument) {
    if (a.type === b.type) {
      return a.name.localeCompare(b.name);
    } else {
      const MY_DOCUMENTS = 'My Documents';
      if (a.type === MY_DOCUMENTS) {
        return -1;
      } else if (b.type === MY_DOCUMENTS) {
        return 1;
      } else {
        return a.type.localeCompare(b.type);
      }
    }
  }

  public sortTypes(a: LibraryDocumentType, b: LibraryDocumentType): number {
    const MY_DOCUMENTS = 'My Documents';
    if (a.name === MY_DOCUMENTS) {
      return -1;
    } else if (b.name === MY_DOCUMENTS) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  }

  public clearCache() {
    this._libraryDocuments$ = null;
    this._countryLibraryDocuments$ = new Map();
  }

  constructor(
    private http: HttpClient,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {
    timer(this.env.cache_timeout, this.env.cache_timeout).subscribe(() =>
      this.clearCache()
    );
  }
}
