import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked } from 'marked';
import { AIService } from 'src/app/services/ai/ai.service';

interface Message {
    text: SafeHtml;
    markdown?: string;
    isUser: boolean;
    annotations?: any;
    thread?: string;
    complete: boolean;
}

@Component({
    selector: 'app-ai-global',
    templateUrl: './ai-global.component.html',
    styleUrls: ['./ai-global.component.scss'],
})
export class AIGlobalComponent implements OnInit {
    userInput: string = '';
    thread: string = '';
    messages: Message[] = [];
    messageQueue: string[] = [];
    loading: boolean = false;
    waiting: boolean = false;
    isChatStarted: boolean = false;
    @Input() useTextEntry: boolean = true;

    @ViewChild('chatHistory') private chatHistory: ElementRef;
    @ViewChild('chatInput') private chatInput: ElementRef;

    constructor(private aiService: AIService, private sanitizer: DomSanitizer) { }

    sendMessage() {
        if (this.loading && this.userInput.trim()) {
            this.messageQueue.push(this.userInput);
            this.userInput = '';
            return;
        }
        if (this.userInput.trim()) {
            if (!this.isChatStarted) {
                this.isChatStarted = true;
            }
            this.messages.push({ text: this.userInput, isUser: true, complete: false });
            this.loading = true;
            this.waiting = true;
            const baseUrl = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
            const new_message = { text: '' as SafeHtml, markdown: '', isUser: false, complete: false } as Message;
            this.messages.push(new_message);

            this.aiService.getResponse(this.userInput, this.thread).subscribe({
                next: async response => {
                    if (response) {
                        this.waiting = false;

                        new_message.markdown = response;
                        // Make the URLs in the markdown absolute, not relative, so they work when copied
                        new_message.markdown = new_message.markdown.replace("(/country", `(${baseUrl}/country`);

                        // Format as markdown and sanitize
                        let markdown: string = await marked.parse(response);

                        // Make sure links open in a new tab
                        markdown = markdown.replace(/<a /g, '<a target="_blank"');

                        // TODO: Make sure we are sanitizing properly
                        const sanitizedHtml = this.sanitizer.bypassSecurityTrustHtml(markdown);

                        // Update the most recent message
                        new_message.text = sanitizedHtml;

                    }
                }, error: (error) => {
                    console.error('Error:', error);
                    this.loading = false;
                    this.waiting = false;
                    new_message.text = 'Sorry, I am having trouble connecting to the server. Please try again later.';
                }, complete: () => {
                    this.waiting = false;
                    this.loading = false;
                    new_message.complete = true;
                    this.processQueue();
                    this.setFocusToChatInput();
                }
            });
            this.userInput = '';
        }
    }

    processQueue() {
        if (this.messageQueue.length > 0) {
            this.userInput = this.messageQueue.shift()!;
            this.sendMessage();
        }
    }


    public openChat(event: Event): void {
        this.isChatStarted = true;
        event.preventDefault();
        setTimeout(() => {
            this.setFocusToChatInput();
        }, 0);
    }

    public copyToClipboard(text: string): void {
        navigator.clipboard.writeText(text).then(() => {
            console.log('Copied to clipboard:', text);
        }, (error) => {
            console.error('Failed to copy to clipboard:', error);
        });
    }

    private scrollToBottom(): void {
        if (this.chatHistory && this.loading) {
            try {
                this.chatHistory.nativeElement.scrollTop = this.chatHistory.nativeElement.scrollHeight;
            } catch (err) {
                console.error('Scroll to bottom failed', err);
            }
        }
    }

    private setFocusToChatInput(): void {
        if (this.chatInput) {
            try {
                this.chatInput.nativeElement.focus();
            } catch (err) {
                console.error('Set focus to chat input failed', err);
            }
        }
    }
    ngOnInit() {
        document.addEventListener('keydown', this.handleEscapeKey.bind(this));
    }

    ngOnDestroy() {
        document.removeEventListener('keydown', this.handleEscapeKey.bind(this));
    }

    ngAfterViewChecked() {
        this.scrollToBottom();
    }

    handleEscapeKey(event: KeyboardEvent) {
        if (event.key === 'Escape' && this.isChatStarted) {
            this.isChatStarted = false;
        }
    }

    closeOverlay(event: MouseEvent) {
        if ((event.target as HTMLElement).classList.contains('chat-overlay')) {
            this.isChatStarted = false;
        }
    }

}

