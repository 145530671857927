<div class="updates__content-wrapper card__wrapper">

  <mat-card appearance="outlined" >
    <mat-card-title class="card__title">
      <h1>Updates</h1>
    </mat-card-title>
    <mat-card-content class="updates__history-list-wrapper">
      <app-update-list></app-update-list>
    </mat-card-content>
  </mat-card>

</div>
