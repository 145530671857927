:root{
  background: linear-gradient(90deg, white, #e0e0e0 50%);
}

body.purchase-page {
  background: linear-gradient(90deg, white, #e0e0e0 50%);

img.logo {
  width: 193px;
  position: absolute;
  left: 20px;
  top: 10px;
}

.purchase__container {
  height: 100%;
}

.purchase__form-wrapper {
  background-color: white;
  height: 100%;
  padding-right: 25px;
  padding-top: 100px;
}
// .purchase__form-wrapper .purchase-row {
//   padding-right: 25px;
// }

.purchase__content-wrapper  {
  padding-left: 25px;
  padding-top: 100px;
}

// .purchase-row {
//   height: 100%;
//   padding-top: 50px;
// }

.purchase-column {
  float: right;
}

.purchase-confirmation {
  float: right;
  max-width: 400px;
  a {
    display: inline-block;
  }
}

.header {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}


a {
  display: block;
  cursor: pointer;
  font-weight: bold;
  color: #0277bd;
  // margin-top: -1em;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  z-index: 999;
  position: relative;
}

mat-form-field {
  // margin-bottom: -2em;
  min-width: 300px;
}

ul {
  margin-left: -20px;
  ul{
    padding-top: 3px;
  }
  li {
    padding-bottom: 3px;
  }
}

.start-purchase-button {
  margin-top: 3em;
  min-width: 300px;
}

table {
  min-width: 400px;
  border: 1px solid gray;
  border-collapse: collapse;
}

tr {
  // background-color: red;
}

td {
  border-bottom: 1px solid gray;
  padding: 5px;
}

.toggle-wrapper {
  margin-top: -30px;
}

.toggle {
  float: right;
  border-top: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
  display: inline-block;
  font-size: 12px;
  div {
    cursor: pointer;
    display: inline-block;
    padding: 5px;
    &:first-child {
      border-right: 1px solid gray;
    }
    &:hover {
      // background-color: gray;
      // color: white;
    }
    &.active {
      background-color: gray;
      color: white;
    }

  }
}
}
