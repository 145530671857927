<div class="countryprofilesection__wrapper"
[class.level-1]="section.level == 1" [class.level-2]="section.level == 2" [class.level-3]="section.level == 3"
 *ngIf='section?.parent && section?.selected'>
  <div class="flex-row-start">
    <h2 class="countryprofilesection__title flex" id="{{section?.id}}">{{ section?.name }}</h2>
  </div>

  <div class="countryprofilesection__flag-placeholder" >
    <img class="countryprofilesection__flag-image" src="/assets/images/flags/{{ (country$ | async)?.slug }}-flag-square-icon-64.png">
  </div>
  <div class="countryprofilesection__content-wrapper">
    <div *ngIf="!editing && isHolidays" class="countryprofilesection__content">
      <p *ngIf="(holidays$|async)?.length"><strong>National Holidays</strong></p>
      <table *ngIf="(holidays$|async)?.length" class="countryprofilesection__holidays">
        <tr>
          <th>Date</th>
          <th>Name</th>
          <th>Description</th>
        </tr>
        <ng-container *ngFor="let event of (holidays$|async) as items; let i = index">
            <tr>
              <td class="countryprofilesection__holidays_date">{{ event?.date|date:'MMMM d' }}</td>
              <td class="countryprofilesection__holidays_name">{{ event?.name }}</td>
              <td class="countryprofilesection__holidays_description">{{ event?.description }}</td>
            </tr>
        </ng-container>
      </table>
      <p *ngIf="(localHolidays$|async)?.length"><strong>Local Holidays</strong></p>
      <table *ngIf="(localHolidays$|async)?.length" class="countryprofilesection__holidays countryprofilesection__holidays--local">
        <tr>
          <th>Date</th>
          <th>Name</th>
          <th>Localities</th>
          <th>Description</th>
        </tr>
        <ng-container *ngFor="let event of (localHolidays$|async) as items; let i = index">
            <tr>
              <td class="countryprofilesection__holidays_date">{{ event?.date|date:'MMMM d' }}</td>
              <td class="countryprofilesection__holidays_name">{{ event?.name }}</td>
              <td class="countryprofilesection__holidays_locations">{{ event?.locations }}</td>
              <td class="countryprofilesection__holidays_description">{{ event?.description }}</td>
            </tr>
        </ng-container>
      </table>
    </div>

    <div *ngIf="!editing && !isHolidays" class="countryprofilesection__content" [innerHTML]="section?.content ? section?.content : (section?.background ? section?.background : '<p><i>Click the \'EDIT\' link above to update this content</i></p>')"></div>
    <div *ngIf="!editing && isHolidays && section?.content" class="countryprofilesection__content" [innerHTML]="section?.content"></div>

    <!-- Obsolete, but saving as sample code for when we add the ability to add notes  -->
    <!-- <div *ngIf="editing" class="countryprofilesection__ckeditor-wrapper mat-elevation-z4">
      <ckeditor
        [editor]="editor"
        [data]="section?.content"
        (change)="onChange($event)"
        (blur)="onBlur($event)"
        [class.mat-elevation-z2]="editing"
        [config]="{toolbar: ['bold', 'italic', '|', 'bulletedlist', 'numberedlist', 'insertTable', '|', 'Undo', 'Redo']}"
        >
      </ckeditor>
    </div> -->
    <!-- No tools of any kind if section content is empty  -->
    <ng-container *ngIf="section?.content">
      <div class="countryprofilesection__tools">
        <a *ngIf="section?.background" mat-button (click)="toggleBackground()" class="countryprofilesection__tool countryprofilesection__background">
          <div class="countryprofilesection__icon-wrapper"><mat-icon svgIcon="book"></mat-icon></div>
          {{ !showBackground ? "Show" : "Hide" }} Background Information
          <div *ngIf='showBackground'  class="countryprofilesection__tool--arrow"> </div>
        </a>
        <a *ngIf="section?.references" mat-button (click)="toggleReferences()" class="countryprofilesection__tool countryprofilesection__references">
          <div class="countryprofilesection__icon-wrapper"><mat-icon svgIcon="references"></mat-icon></div>
          {{ !showReferences ? "Show" : "Hide" }} References
          <div *ngIf='showReferences'  class="countryprofilesection__tool--arrow"> </div>
        </a>
        <a *ngIf="(history$ | async)?.length" (click)="toggleHistory()" mat-button class="countryprofilesection__tool countryprofilesection__histor">
          <div class="countryprofilesection__icon-wrapper"><mat-icon svgIcon="history"></mat-icon></div>
          {{ !showHistory ? "Show" : "Hide" }} History
          <div *ngIf='showHistory'  class="countryprofilesection__tool--arrow"> </div>
        </a>
      </div>
      <div *ngIf='showBackground' class="countryprofilesection__tool-content countryprofilesection__background">
        <div class="countryprofilesection__tool-header">Background Information</div>
        <div [innerHTML]="section?.background"></div>
      </div>
      <div *ngIf='showReferences' class="countryprofilesection__tool-content countryprofilesection__references">
        <div class="countryprofilesection__tool-header">References</div>
        <div [innerHTML]="section?.references"></div>
      </div>
      <div *ngIf='showHistory' class="countryprofilesection__tool-content countryprofilesection__history">
        <div class="countryprofilesection__tool-header">History</div>
        <div *ngFor="let h of (history$ | async); let i = index" class="countryprofilesection__historywrapper">
          <div class="countryprofilesection__historydate">{{h.date|date:'MMMM yyyy' }}</div>
          <div class="countryprofilesection__historydescription">{{h.update_description}}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
