<a href="https://www.globalpeoplestrategist.com"><img class="logo" src="/assets/images/logo-header.png" /></a>
<div class="purchase__container">

  <div class="purchase__form-wrapper">
      <h1 class="header">
        Redirecting....
      </h1>

  </div>

</div>
