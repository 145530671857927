// Colors

$link-bright: #03A9F4;
$link-light: #0277BD;
$link-dark: #003A5C;
$background-dark: #003A5C;
$text: #263238;
$border-gray: #CCD4D8;
$text-gray: #9E9E9E;
$red: #E91E63;
