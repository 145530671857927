<mat-toolbar  class="countrylistitem__toolbar" [class.expanded]="show_updates">
  <div class="countrylistitem__flag flex-50px">
    <div class="countrylistitem__flag-placeholder">
      <a [routerLink]="['/country', country.slug]" [class.disabled]="country.type !== 'standard'" ><img class="countrylistitem__flag-image" src="/assets/images/flags/{{ country.slug }}-flag-square-icon-64.png"
           onError="this.src='/assets/images/flags/_placeholder-flag-square-icon-64.png'"></a>
    </div>
  </div>
  <div class="countrylistitem__name"  ><a [routerLink]="['/country', country.slug]" >{{ country.name }}</a></div >
  <div class="countrylistitem__link-wrapper">
    <div class="countrylistitem__updates countrylistitem__link">
      <a mat-button (click)="toggle()" class="countrylistitem__updates countrylistitem__link">
        <div class="countrylistitem__icon-wrapper"><mat-icon svgIcon="bell"></mat-icon></div>
        <span class="countrylistitem__collapse-tiny">Updates</span>
      </a>
    </div>
    <div class="countrylistitem__profile countrylistitem__link">
      <a mat-button [routerLink]="['/country', country.slug, 'profile']" [disabled]="country.type !== 'standard'">
        <div class="countrylistitem__icon-wrapper"><mat-icon svgIcon="globeperson"></mat-icon></div>
        <span class="countrylistitem__collapse-small"><span class="countrylistitem__collapse-medium">Country </span>Profile</span>
      </a>
    </div>
    <div class="countrylistitem__snapshot countrylistitem__link">
      <a mat-button [routerLink]="['/country', country.slug, 'snapshot']">
        <div class="countrylistitem__icon-wrapper"><mat-icon svgIcon="camera"></mat-icon></div>
        <span class="countrylistitem__collapse-small"><span class="countrylistitem__collapse-medium">Country </span>Snapshot</span>
      </a>
    </div>
  </div>
</mat-toolbar>
<app-country-updates *ngIf="show_updates" [country$]="country$"></app-country-updates>
