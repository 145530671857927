<div  class="changepassword__content-wrapper flex-row-start">

  <mat-card appearance="outlined" class="flex-fill">
    <mat-card-title>
      <h1>Change Password</h1>
    </mat-card-title>
    <mat-card-content class="updates__history-list-wrapper">

      <div class="changepassword__section-main" style="display: flex; flex-direction: row;">
        <form (ngSubmit)="changePassword()" style="display: flex; flex-direction: column;" >
        
          <!-- <div class="changepassword__text">
            Please choose your new password
          </div> -->
          <mat-form-field  appearance="outline" >
            <mat-label>New Password</mat-label>
            <input matInput name="password1" type="password" [(ngModel)]="password1" autocomplete="new-password" placeholder="New Password">
          </mat-form-field>

          <mat-form-field  appearance="outline">
            <mat-label>Confirm New Password</mat-label>
            <input matInput name="password2" type="password" [(ngModel)]="password2" autocomplete="new-password">
          </mat-form-field>

          <div class="changepassword__button-wrapper">
            <a mat-flat-button color="accent" (click)="changePassword()" type="submit">CHANGE PASSWORD</a>
          </div>
          <div *ngIf="error" class="changepassword__error-wrapper">
            <mat-error><div >{{error}}</div></mat-error>
          </div>
          <div *ngIf="message">{{message}}</div>
        </form>
      </div>

    </mat-card-content>
  </mat-card>
</div>
