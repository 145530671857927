<h1 mat-dialog-title>
  <ng-container *ngIf="data.id; then editHeader; else addHeader;"></ng-container>&nbsp;EVENT
  <ng-template #addHeader>ADD</ng-template>
  <ng-template #editHeader>EDIT</ng-template>
</h1>
<!-- <p>In order to create an event all fields are required</p> -->

<div #formElement mat-dialog-content class="form">
  <div>
    <!-- event title -->
    <!-- <mat-label>EVENT TITLE</mat-label> -->
    <mat-form-field  appearance="outline" required class="flex-fill" style="margin-top: 5px;">
      <mat-label>Event Name</mat-label>
      <input matInput [(ngModel)]="formData.name" #name="ngModel" required ngDefaultControl>
    </mat-form-field>
    <!-- date -->
    <!-- <mat-label>DATE</mat-label> -->
    <mat-form-field appearance="outline" class="form__date" [ngClass]="{'mat-form-field-invalid': !checkDateFormat()}" >
      <mat-label>Event Date</mat-label>
      <input placeholder="mm/dd/yyyy" matInput [matDatepicker]="picker" [(ngModel)]="formData.date" required ngDefaultControl>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <!-- country -->
    <!-- <mat-label>COUNTRY</mat-label> -->
    <mat-form-field class="flex-fill" appearance="outline" floatLabel="always" >
      <mat-label>Country</mat-label>
      <mat-select [(ngModel)]="formData.country" [compareWith]="compareWithFnCountry" #selectedcountries="ngModel"
        placeholder="Select a country" required ngDefaultControl>
        <mat-option *ngFor="let country of (countries$|async)" [value]="country">{{country.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- topic -->
    <!-- <mat-label>TOPIC</mat-label> -->
    <mat-form-field  appearance="outline" class="form__topic flex-fill" floatLabel="always" >
      <mat-label>Topic</mat-label>
      <mat-select placeholder="Select a topic" [(ngModel)]="formData.event_type" #selectedtopics="ngModel" required ngDefaultControl>
        <mat-option *ngFor="let topic of (topics$ |async)" [value]="topic" (click)="selectTopic()">{{topic}}
        </mat-option>
        <!-- default option "My Events" -->
        <mat-option *ngIf="!myEventsFound" [value]="myEvents" (click)="selectTopic()">{{myEvents.name}}</mat-option>
        <!-- New topic value -->

        <!-- option "Add a new topic" -->
        <mat-option [value]="customTopicValue">
          <a><i>Add a new topic...</i></a>
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="formData?.event_type === customTopicValue"  appearance="outline" required class="form__topic flex-fill" >
      <mat-label>Add a new topic</mat-label>
      <input autofocus class="custom-topic-input" matInput [(ngModel)]="formData.custom_event_type" required #customTopicInput ngDefaultControl>
    </mat-form-field>
    <!-- description -->
    <mat-form-field class="flex-fill" appearance="outline" >
      <mat-label>Event Description</mat-label>
      <textarea placeholder="Enter a description for the event" matInput matNativeControl
        [(ngModel)]="formData.description" rows="4" ngDefaultControl></textarea>
    </mat-form-field>

  </div>
</div>
<!-- actions -->
<mat-dialog-actions align="end">
  <button mat-flat-button [mat-dialog-close]="true">Cancel</button>
  <button mat-flat-button color="accent" [mat-dialog-close]="true" [disabled]="!checkFieldsRequired()"
    (click)="saveEvent(data.id)">
    <ng-container *ngIf="data.id; then edit; else add;"></ng-container>
    <ng-template #add>Add to calendar</ng-template>
    <ng-template #edit>Update Event</ng-template>
  </button>
</mat-dialog-actions>
