import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';

// Don't let the user access pages that they need to be logged in to see
@Injectable()
export class AuthGuard  {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      this.authService.refreshToken();
      return true;
    } else {
      // See if we have a token that can authorize the user
      if (route.queryParams.auth) {
        this.authService.tokenLogin(route.queryParams.auth);
        if (this.authService.isLoggedIn()) {
          return true;
        }
      }

      // Store the attempted URL for redirecting
      this.authService.redirectUrl = state.url;
      this.authService.logout();
      this.router.navigate(['login']);
      return false;
    }
  }
}

