import { Injectable, Inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  catchError,
  shareReplay,
  map,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { OrganizationType, User, UserRole } from 'src/app/models/user';
import { environment } from 'src/environments/environment';
import { ServiceBase } from '../service-base';
import { Router } from '@angular/router';
import Rollbar from 'rollbar';
import { RollbarService } from 'src/app/util/rollbar';

@Injectable({
  providedIn: 'root',
})
export class UserService implements ServiceBase {
  private env = environment;
  private _currentUser: Observable<User>;

  public currentUser(forceRedirectToLogin: boolean = true): Observable<User> {
    if (!this._currentUser) {
      this._currentUser = this.getCurrentUser().pipe(
        catchError(() => {
          if (forceRedirectToLogin) {
            this.rollbar.warn(
              'Could not get current user - redirecting to login page'
            );
            localStorage.removeItem('token');
            localStorage.removeItem('expires_at');
            this.router.navigate(['login']);
          } else {
            this.rollbar.warn('Could not get current user - not redirecting');
          }
          return of(null);
        })
      );
    }
    return this._currentUser;
  }

  private getCurrentUser(): Observable<User> {
    return this.http
      .get<User>(this.env.api_root.concat('headless/user/'))
      .pipe(shareReplay(1));
  }

  public saveUser(user: User): Observable<User> {
    return this.http.patch<User>(
      this.env.api_root.concat('headless/user/', user.id.toString(), '/'),
      user
    );
  }

  public canUpdate(): Observable<boolean> {
    if (this.currentUser()) {
      return this.currentUser().pipe(
        map((user) => {
          return (
            (user && user.organization_role === UserRole.OWNER) ||
            user.organization_role === UserRole.EDITOR
          );
        })
      );
    }
  }

  public organizationIsNotificationOnly(): Observable<boolean> {
    if (this.currentUser()) {
      return this.currentUser().pipe(
        map((user) => {
          return user && user.organization_type !== OrganizationType.STANDARD;
        })
      );
    }
  }

  public canManage(): Observable<boolean> {
    if (this.currentUser()) {
      return this.currentUser().pipe(
        map((user) => {
          return user && user.organization_role === UserRole.OWNER;
        })
      );
    }
  }

  public isAdmin(): Observable<boolean> {
    if (this.currentUser()) {
      return this.currentUser().pipe(map((user) => user && user.is_staff));
    }
  }

  public hasSetPassword(): Observable<boolean> {
    if (this.currentUser()) {
      return this.currentUser().pipe(map((user) => user && user.is_password_set));
    }
  }

  public clearCache() {
    this._currentUser = null;
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(RollbarService) private rollbar: Rollbar
  ) { }
}
