<div  class="organization__content-wrapper card__wrapper">

  <mat-card appearance="outlined" >
    <mat-card-title class="card__title">
      <h1>Manage Organization</h1>
    </mat-card-title>
    <mat-card-content>
      <div class="organization__header">
        <h2>{{ (organization$|async)?.name }}</h2>
      </div>

      <div class="organization__document-wrapper flex-row">
        <div  class="organization__section organization__section-left">
          <app-organization-country-list></app-organization-country-list>
        </div>
        <div class="organization__section organization__section-main">
          <app-organization-member-list></app-organization-member-list>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
