import { Component, Inject, Renderer2 } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RollbarService } from './util/rollbar';
import Rollbar from 'rollbar';
import FontFaceObserver from 'fontfaceobserver';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'angular-gps';

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    @Inject(RollbarService) private rollbar: Rollbar,
    private renderer: Renderer2
  ) {

    const materialIcons = new FontFaceObserver('Material Icons');
    materialIcons.load(null, 10000).then(() => this.renderer.addClass(document.body, `material-icons-loaded`));

    this.matIconRegistry.addSvgIcon('bell', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/bell.svg'));
    this.matIconRegistry.addSvgIcon('book', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/book.svg'));
    this.matIconRegistry.addSvgIcon('calculator', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/calculator.svg'));
    this.matIconRegistry.addSvgIcon('camera', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/camera.svg'));
    this.matIconRegistry.addSvgIcon('checked', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/checked.svg'));
    this.matIconRegistry.addSvgIcon('document', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/document.svg'));
    this.matIconRegistry.addSvgIcon('down', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/down.svg'));
    this.matIconRegistry.addSvgIcon('download', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/download.svg'));
    this.matIconRegistry.addSvgIcon('gear', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/gear.svg'));
    this.matIconRegistry.addSvgIcon('globe', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/globe.svg'));
    this.matIconRegistry.addSvgIcon('globeperson',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/globeperson.svg'));
    this.matIconRegistry.addSvgIcon('help', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/help.svg'));
    this.matIconRegistry.addSvgIcon('history', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/history.svg'));
    this.matIconRegistry.addSvgIcon('home', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/home.svg'));
    this.matIconRegistry.addSvgIcon('location', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/location.svg'));
    this.matIconRegistry.addSvgIcon('pencil', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/pencil.svg'));
    this.matIconRegistry.addSvgIcon('plus', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/plus.svg'));
    this.matIconRegistry.addSvgIcon('pdf', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/pdf.svg'));
    this.matIconRegistry.addSvgIcon('print', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/print.svg'));
    this.matIconRegistry.addSvgIcon('references',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/references.svg'));
    this.matIconRegistry.addSvgIcon('search', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/search.svg'));
    this.matIconRegistry.addSvgIcon('trash', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/trash.svg'));
    this.matIconRegistry.addSvgIcon('up', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/up.svg'));
    this.matIconRegistry.addSvgIcon('word', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/word.svg'));
    this.matIconRegistry.addSvgIcon('excel', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/excel.svg'));
    this.matIconRegistry.addSvgIcon('left', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/left.svg'));
    this.matIconRegistry.addSvgIcon('right', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/right.svg'));
    this.matIconRegistry.addSvgIcon('calendar', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/calendar.svg'));
    this.matIconRegistry.addSvgIcon('table', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/table.svg'));
    this.matIconRegistry.addSvgIcon('list', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/list.svg'));
    this.matIconRegistry.addSvgIcon('apple', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/apple.svg'));
    this.matIconRegistry.addSvgIcon('google', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/google.svg'));
    this.matIconRegistry.addSvgIcon('office365', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/office365.svg'));
    this.matIconRegistry.addSvgIcon('outlook', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/outlook.svg'));
    this.matIconRegistry.addSvgIcon('outlookcom', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/outlookcom.svg'));
    this.matIconRegistry.addSvgIcon('link', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/link.svg'));
    this.matIconRegistry.addSvgIcon('chat', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/icons/chat.svg'));
  }
}
