import { Component, OnInit, Input, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Country } from 'src/app/models/country';
import { take, finalize, mergeMap, map, tap } from 'rxjs/operators';
import { DocumentService } from 'src/app/services/document/document.service';

import htmlDocx from 'html-docx-js/dist/html-docx';
import saveAs from 'file-saver';
import { RollbarService } from 'src/app/util/rollbar';
import Rollbar from 'rollbar';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-country-profile-download',
  templateUrl: './country-profile-download.component.html',
  styleUrls: ['./country-profile-download.component.scss']
})
export class CountryProfileDownloadComponent implements OnInit {

  @Input()
  public country$: Observable<Country>;

  private _country: Country;

  private getDownloadContent(): Observable<string> {
    if (this.country$) {
      return this.country$.pipe(take(1)).pipe(
        tap((c) => this._country = c),
        mergeMap((country) => this.documentService.getCountryProfileSections(country.iso2)),
        take(1),
        map((sections) => sections.filter(s => s.selected)),
        map((sections) => sections.map((s) => { if (s.parent) return "<h3>" + s.name + "</h3>" + s.content; else return "" })), // Ignore header section
        map((sectionsText) => sectionsText.reduce((acc, current) => acc + current), ""),
      )
    }
  }

  public download() {
    this.getDownloadContent().subscribe(
      (result) => {
        let header = "<!DOCTYPE html>\n<html><head><meta charset='utf-8'></head><body><h1>" + this._country.name + " Country Profile</h1>";
        let footer = "</body></html>"
        let sourceHTML = htmlDocx.asBlob(header + result + footer);
        saveAs(sourceHTML, this._country.name + " Country Profile.docx");
        this.mixpanelService.track('Download Country Profile', { "GPS Country": this._country.name });
      },
      (err) => {
        this.rollbar.error("Problem with file download");
      }
    )
    return false;
  }

  constructor(private documentService: DocumentService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private mixpanelService: MixpanelService) { }

  ngOnInit() {
  }

}
