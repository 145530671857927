<div class="calendarlistcountry__wrapper flex-row">
  <div class="countrylistitem__flag" class="flex-60px">
    <div class="countrylistitem__flag-placeholder">
      <img class="countrylistitem__flag-image" src="/assets/images/flags/{{ country?.slug }}-flag-square-icon-64.png"
        onError="this.src='/assets/images/flags/_placeholder-flag-square-icon-64.png'" />
    </div>
  </div>

  <div class="flex">
    <div class="calendarlistcountry__countryname">{{ country?.name }}</div>
    <div *ngFor="let event of events; let i = index" class="calendarlistcountry__event">
      <div *ngIf="i == 0  || (events[i].name != events[i-1].name) ">
        <div class="calendarlistcountry__eventname">{{ event.name }}</div>
        <div class="calendarlistcountry__eventdescription" *ngIf="!event.holiday_type">{{ event.description }}</div>
        <div class="calendarlistcountry__eventlabel" *ngIf="event.holiday_type">Public Holiday</div>
        <div class="calendarlistcountry__eventlabel" *ngIf="event.event_type">{{ event.event_type.name }}</div>
        <div mat-dialog-actions class="calendarlistcountry__actions" *ngIf="(canEdit$ | async) && event.organization">
          <a class="edit" mat-button [mat-dialog-close]="true" (click)="editEvent(event.id, event)">Edit</a>
          <a class="delete" mat-button [mat-dialog-close]="true" (click)="deleteEvent(event)">
            <mat-icon svgIcon="trash"></mat-icon> 
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
