import Rollbar from 'rollbar'; // When using Typescript < 3.6.0.
// `import Rollbar from 'rollbar';` is the required syntax for Typescript 3.6.x.
// However, it will only work when setting either `allowSyntheticDefaultImports`
// or `esModuleInterop` in your Typescript options.

import { VERSION } from '../../environments/version';

import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler,
} from '@angular/core';
import { environment } from 'src/environments/environment';

const rollbarConfig = {
  accessToken: '3d8debb0b0ad4b5badc2fed48e626636',
  captureUncaught:
    environment.production || environment.environment_name === 'Staging',
  captureUnhandledRejections:
    environment.production || environment.environment_name === 'Staging',
  payload: {
    environment: environment.environment_name,
    client: {
      javascript: {
        code_version: VERSION.hash,
        guess_uncaught_frames: true,
      },
    },
  },
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) { }

  handleError(err: any): void {
    this.rollbar.error(err.originalError || err);
    if (!environment.production) {
      console.error(err.originalError || err);
    }
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
