import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { map, tap, shareReplay, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';

import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';
import { CountryService } from '../country/country.service';
import { DocumentService } from '../document/document.service';
import { OrganizationService } from '../organization/organization.service';
import { UpdateService } from '../update/update.service';
import { EventService } from '../event/event.service';
import { MixpanelService } from '../mixpanel/mixpanel.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // Implementation based on https://humberto.io/blog/jwt-authentication-with-angular-and-django/
  private apiRoot = environment.api_root;

  // http options used for making API calls
  private httpOptions: any;

  // the username of the logged in user
  public username: string;

  // the desired redirect url after login
  public redirectUrl: string;

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private countryService: CountryService,
    private documentService: DocumentService,
    private organizationService: OrganizationService,
    private updateService: UpdateService,
    private eventService: EventService,
    private mixpanelService: MixpanelService
  ) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
  }

  // Uses http.post() to get an auth token from djangorestframework-jwt endpoint and return the username if successful
  public login(user: {
    username: string;
    password: string;
  }): Observable<Boolean> {
    return this.http
      .post<any>(
        this.apiRoot.concat('api-token-auth/'),
        JSON.stringify(user),
        this.httpOptions
      )
      .pipe(
        map((response) => {
          this.setLoginSession(response);
          return this.isLoggedIn();
        }),
        tap((_) => this.mixpanelService.init(user.username)),
        tap((_) => this.mixpanelService.track('Login'))
      );
  }

  public tokenLogin(token: string) {
    this.setLoginSessionWithToken(token);
  }

  public logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('expires_at');
    this.userService.clearCache();
    this.countryService.clearCache();
    this.documentService.clearCache();
    this.organizationService.clearCache();
    this.updateService.clearCache();
    this.eventService.clearCache();
  }

  public requestResetEmail(email: string): Observable<any> {
    return this.http.post<any>(
      this.apiRoot.concat('rest-auth/password/reset/'),
      { email },
      this.httpOptions
    );
  }

  public resetPasswordWithToken(
    uid: string,
    token: string,
    new_password1: string,
    new_password2: string
  ) {
    return this.http.post<any>(
      this.apiRoot.concat('rest-auth/password/reset/confirm/'),
      {
        uid,
        token,
        new_password1,
        new_password2,
      },
      this.httpOptions
    );
  }

  public changePassword(new_password1: string, new_password2: string) {
    return this.http.post<any>(
      this.apiRoot.concat('rest-auth/password/change/'),
      {
        old_password: '',
        new_password1,
        new_password2,
      },
      this.httpOptions
    );
  }

  public refreshToken() {
    if (
      dayjs().isAfter(this.getExpiration().subtract(3, 'day')) &&
      dayjs().isBefore(this.getExpiration())
    ) {
      return this.http
        .post(this.apiRoot.concat('api-token-refresh/'), {
          token: localStorage.getItem('token'),
        })
        .pipe(
          tap((response) => this.setLoginSession(response)),
          shareReplay()
        )
        .subscribe();
    }
  }

  private getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);

    return dayjs(expiresAt);
  }

  public isLoggedIn(): boolean {
    return dayjs().isBefore(this.getExpiration());
  }

  public isLoggedOut(): boolean {
    return !this.isLoggedIn();
  }

  private setLoginSession(authResult) {
    return this.setLoginSessionWithToken(authResult.token);
  }

  private setLoginSessionWithToken(token) {
    const payload = <JWTPayload>jwtDecode(token);
    const expiresAt = dayjs.unix(payload.exp);

    // Save in local storage so we can use it when the user returns
    localStorage.setItem('token', token);
    localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));

    this.userService
      .currentUser(false)
      .pipe(take(1))
      .subscribe((user) => {
        this.mixpanelService.init(user.email);
        this.mixpanelService.track('Login success from V1 site');
      });
  }
}

// Add the authorization header to all requests
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');

    if (token) {
      const cloned = req.clone({
        headers: req.headers.set('Authorization', 'JWT '.concat(token)),
      });
      return next.handle(cloned);
    } else {
      return next.handle(req);
    }
  }
}

interface JWTPayload {
  user_id: number;
  username: string;
  email: string;
  exp: number;
}
