!function (t, e) {
  "object" == typeof exports && "object" == typeof module ? module.exports = e() : "function" == typeof define && define.amd ? define([], e) : "object" == typeof exports ? exports.rollbar = e() : t.rollbar = e();
}(this, function () {
  return function (t) {
    var e = {};
    function r(n) {
      if (e[n]) return e[n].exports;
      var o = e[n] = {
        i: n,
        l: !1,
        exports: {}
      };
      return t[n].call(o.exports, o, o.exports, r), o.l = !0, o.exports;
    }
    return r.m = t, r.c = e, r.d = function (t, e, n) {
      r.o(t, e) || Object.defineProperty(t, e, {
        enumerable: !0,
        get: n
      });
    }, r.r = function (t) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(t, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(t, "__esModule", {
        value: !0
      });
    }, r.t = function (t, e) {
      if (1 & e && (t = r(t)), 8 & e) return t;
      if (4 & e && "object" == typeof t && t && t.__esModule) return t;
      var n = Object.create(null);
      if (r.r(n), Object.defineProperty(n, "default", {
        enumerable: !0,
        value: t
      }), 2 & e && "string" != typeof t) for (var o in t) r.d(n, o, function (e) {
        return t[e];
      }.bind(null, o));
      return n;
    }, r.n = function (t) {
      var e = t && t.__esModule ? function () {
        return t.default;
      } : function () {
        return t;
      };
      return r.d(e, "a", e), e;
    }, r.o = function (t, e) {
      return Object.prototype.hasOwnProperty.call(t, e);
    }, r.p = "", r(r.s = 5);
  }([function (t, e, r) {
    "use strict";

    var n = r(10),
      o = {},
      i = !1;
    function a(t, e) {
      return e === s(t);
    }
    function s(t) {
      var e = typeof t;
      return "object" !== e ? e : t ? t instanceof Error ? "error" : {}.toString.call(t).match(/\s([a-zA-Z]+)/)[1].toLowerCase() : "null";
    }
    function u(t) {
      return a(t, "function");
    }
    function c(t) {
      var e = Function.prototype.toString.call(Object.prototype.hasOwnProperty).replace(/[\\^$.*+?()[\]{}|]/g, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?"),
        r = RegExp("^" + e + "$");
      return l(t) && r.test(t);
    }
    function l(t) {
      var e = typeof t;
      return null != t && ("object" == e || "function" == e);
    }
    function p(t, e, r) {
      var n,
        o,
        i,
        s = a(t, "object"),
        u = a(t, "array"),
        c = [];
      if (s && -1 !== r.indexOf(t)) return t;
      if (r.push(t), s) for (n in t) Object.prototype.hasOwnProperty.call(t, n) && c.push(n);else if (u) for (i = 0; i < t.length; ++i) c.push(i);
      var l = s ? {} : [],
        p = !0;
      for (i = 0; i < c.length; ++i) o = t[n = c[i]], l[n] = e(n, o, r), p = p && l[n] === t[n];
      return 0 == c.length || p ? t : l;
    }
    function f() {
      return "********";
    }
    function h() {
      var t = _();
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (e) {
        var r = (t + 16 * Math.random()) % 16 | 0;
        return t = Math.floor(t / 16), ("x" === e ? r : 7 & r | 8).toString(16);
      });
    }
    i || (i = !0, a(JSON, "undefined") || (c(JSON.stringify) && (o.stringify = JSON.stringify), c(JSON.parse) && (o.parse = JSON.parse)), u(o.stringify) && u(o.parse) || r(11)(o));
    var d = {
      strictMode: !1,
      key: ["source", "protocol", "authority", "userInfo", "user", "password", "host", "port", "relative", "path", "directory", "file", "query", "anchor"],
      q: {
        name: "queryKey",
        parser: /(?:^|&)([^&=]*)=?([^&]*)/g
      },
      parser: {
        strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
        loose: /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/
      }
    };
    function m(t, e) {
      var r, n;
      try {
        r = o.stringify(t);
      } catch (o) {
        if (e && u(e)) try {
          r = e(t);
        } catch (t) {
          n = t;
        } else n = o;
      }
      return {
        error: n,
        value: r
      };
    }
    function g(t, e) {
      return function (r, n) {
        try {
          e(r, n);
        } catch (e) {
          t.error(e);
        }
      };
    }
    var v = ["log", "network", "dom", "navigation", "error", "manual"],
      y = ["critical", "error", "warning", "info", "debug"];
    function b(t, e) {
      for (var r = 0; r < t.length; ++r) if (t[r] === e) return !0;
      return !1;
    }
    function w(t, e) {
      var r = e.split("."),
        n = r.length - 1;
      try {
        for (var o = 0; o <= n; ++o) o < n ? t = t[r[o]] : t[r[o]] = "********";
      } catch (t) {}
    }
    function _() {
      return Date.now ? +Date.now() : +new Date();
    }
    t.exports = {
      addParamsAndAccessTokenToPath: function (t, e, r) {
        (r = r || {}).access_token = t;
        var n,
          o = [];
        for (n in r) Object.prototype.hasOwnProperty.call(r, n) && o.push([n, r[n]].join("="));
        var i = "?" + o.sort().join("&");
        (e = e || {}).path = e.path || "";
        var a,
          s = e.path.indexOf("?"),
          u = e.path.indexOf("#");
        -1 !== s && (-1 === u || u > s) ? (a = e.path, e.path = a.substring(0, s) + i + "&" + a.substring(s + 1)) : -1 !== u ? (a = e.path, e.path = a.substring(0, u) + i + a.substring(u)) : e.path = e.path + i;
      },
      createItem: function (t, e, r, o, i) {
        for (var a, u, c, l, p, f, d = [], m = [], v = 0, y = t.length; v < y; ++v) {
          var b = s(f = t[v]);
          switch (m.push(b), b) {
            case "undefined":
              break;
            case "string":
              a ? d.push(f) : a = f;
              break;
            case "function":
              l = g(e, f);
              break;
            case "date":
              d.push(f);
              break;
            case "error":
            case "domexception":
            case "exception":
              u ? d.push(f) : u = f;
              break;
            case "object":
            case "array":
              if (f instanceof Error || "undefined" != typeof DOMException && f instanceof DOMException) {
                u ? d.push(f) : u = f;
                break;
              }
              if (o && "object" === b && !p) {
                for (var w = 0, x = o.length; w < x; ++w) if (void 0 !== f[o[w]]) {
                  p = f;
                  break;
                }
                if (p) break;
              }
              c ? d.push(f) : c = f;
              break;
            default:
              if (f instanceof Error || "undefined" != typeof DOMException && f instanceof DOMException) {
                u ? d.push(f) : u = f;
                break;
              }
              d.push(f);
          }
        }
        d.length > 0 && ((c = n(c)).extraArgs = d);
        var k = {
          message: a,
          err: u,
          custom: c,
          timestamp: _(),
          callback: l,
          notifier: r,
          diagnostic: {},
          uuid: h()
        };
        return function (t, e) {
          e && void 0 !== e.level && (t.level = e.level, delete e.level);
          e && void 0 !== e.skipFrames && (t.skipFrames = e.skipFrames, delete e.skipFrames);
        }(k, c), o && p && (k.request = p), i && (k.lambdaContext = i), k._originalArgs = t, k.diagnostic.original_arg_types = m, k;
      },
      addErrorContext: function (t, e) {
        var r = t.data.custom || {},
          o = !1;
        try {
          for (var i = 0; i < e.length; ++i) e[i].hasOwnProperty("rollbarContext") && (r = n(r, e[i].rollbarContext), o = !0);
          o && (t.data.custom = r);
        } catch (e) {
          t.diagnostic.error_context = "Failed: " + e.message;
        }
      },
      createTelemetryEvent: function (t) {
        for (var e, r, n, o, i = 0, a = t.length; i < a; ++i) {
          switch (s(o = t[i])) {
            case "string":
              !e && b(v, o) ? e = o : !n && b(y, o) && (n = o);
              break;
            case "object":
              r = o;
          }
        }
        return {
          type: e || "manual",
          metadata: r || {},
          level: n
        };
      },
      filterIp: function (t, e) {
        if (t && t.user_ip && !0 !== e) {
          var r = t.user_ip;
          if (e) try {
            var n;
            if (-1 !== r.indexOf(".")) (n = r.split(".")).pop(), n.push("0"), r = n.join(".");else if (-1 !== r.indexOf(":")) {
              if ((n = r.split(":")).length > 2) {
                var o = n.slice(0, 3),
                  i = o[2].indexOf("/");
                -1 !== i && (o[2] = o[2].substring(0, i));
                r = o.concat("0000:0000:0000:0000:0000").join(":");
              }
            } else r = null;
          } catch (t) {
            r = null;
          } else r = null;
          t.user_ip = r;
        }
      },
      formatArgsAsString: function (t) {
        var e,
          r,
          n,
          o = [];
        for (e = 0, r = t.length; e < r; ++e) {
          switch (s(n = t[e])) {
            case "object":
              (n = (n = m(n)).error || n.value).length > 500 && (n = n.substr(0, 497) + "...");
              break;
            case "null":
              n = "null";
              break;
            case "undefined":
              n = "undefined";
              break;
            case "symbol":
              n = n.toString();
          }
          o.push(n);
        }
        return o.join(" ");
      },
      formatUrl: function (t, e) {
        if (!(e = e || t.protocol) && t.port && (80 === t.port ? e = "http:" : 443 === t.port && (e = "https:")), e = e || "https:", !t.hostname) return null;
        var r = e + "//" + t.hostname;
        return t.port && (r = r + ":" + t.port), t.path && (r += t.path), r;
      },
      get: function (t, e) {
        if (t) {
          var r = e.split("."),
            n = t;
          try {
            for (var o = 0, i = r.length; o < i; ++o) n = n[r[o]];
          } catch (t) {
            n = void 0;
          }
          return n;
        }
      },
      handleOptions: function (t, e, r) {
        var o = n(t, e, r);
        return !e || e.overwriteScrubFields || e.scrubFields && (o.scrubFields = (t.scrubFields || []).concat(e.scrubFields)), o;
      },
      isError: function (t) {
        return a(t, "error") || a(t, "exception");
      },
      isFunction: u,
      isIterable: function (t) {
        var e = s(t);
        return "object" === e || "array" === e;
      },
      isNativeFunction: c,
      isType: a,
      isObject: l,
      isString: function (t) {
        return "string" == typeof t || t instanceof String;
      },
      jsonParse: function (t) {
        var e, r;
        try {
          e = o.parse(t);
        } catch (t) {
          r = t;
        }
        return {
          error: r,
          value: e
        };
      },
      LEVELS: {
        debug: 0,
        info: 1,
        warning: 2,
        error: 3,
        critical: 4
      },
      makeUnhandledStackInfo: function (t, e, r, n, o, i, a, s) {
        var u = {
          url: e || "",
          line: r,
          column: n
        };
        u.func = s.guessFunctionName(u.url, u.line), u.context = s.gatherContext(u.url, u.line);
        var c = document && document.location && document.location.href,
          l = window && window.navigator && window.navigator.userAgent;
        return {
          mode: i,
          message: o ? String(o) : t || a,
          url: c,
          stack: [u],
          useragent: l
        };
      },
      merge: n,
      now: _,
      redact: f,
      sanitizeUrl: function (t) {
        var e = function (t) {
          if (!a(t, "string")) return;
          for (var e = d, r = e.parser[e.strictMode ? "strict" : "loose"].exec(t), n = {}, o = 0, i = e.key.length; o < i; ++o) n[e.key[o]] = r[o] || "";
          return n[e.q.name] = {}, n[e.key[12]].replace(e.q.parser, function (t, r, o) {
            r && (n[e.q.name][r] = o);
          }), n;
        }(t);
        return e ? ("" === e.anchor && (e.source = e.source.replace("#", "")), t = e.source.replace("?" + e.query, "")) : "(unknown)";
      },
      scrub: function (t, e, r) {
        if (e = e || [], r) for (var n = 0; n < r.length; ++n) w(t, r[n]);
        var o = function (t) {
            for (var e, r = [], n = 0; n < t.length; ++n) e = "^\\[?(%5[bB])?" + t[n] + "\\[?(%5[bB])?\\]?(%5[dD])?$", r.push(new RegExp(e, "i"));
            return r;
          }(e),
          i = function (t) {
            for (var e, r = [], n = 0; n < t.length; ++n) e = "\\[?(%5[bB])?" + t[n] + "\\[?(%5[bB])?\\]?(%5[dD])?", r.push(new RegExp("(" + e + "=)([^&\\n]+)", "igm"));
            return r;
          }(e);
        function s(t, e) {
          return e + "********";
        }
        return p(t, function t(e, r, n) {
          var u = function (t, e) {
            var r;
            for (r = 0; r < o.length; ++r) if (o[r].test(t)) {
              e = "********";
              break;
            }
            return e;
          }(e, r);
          return u === r ? a(r, "object") || a(r, "array") ? p(r, t, n) : function (t) {
            var e;
            if (a(t, "string")) for (e = 0; e < i.length; ++e) t = t.replace(i[e], s);
            return t;
          }(u) : u;
        }, []);
      },
      set: function (t, e, r) {
        if (t) {
          var n = e.split("."),
            o = n.length;
          if (!(o < 1)) if (1 !== o) try {
            for (var i = t[n[0]] || {}, a = i, s = 1; s < o - 1; ++s) i[n[s]] = i[n[s]] || {}, i = i[n[s]];
            i[n[o - 1]] = r, t[n[0]] = a;
          } catch (t) {
            return;
          } else t[n[0]] = r;
        }
      },
      stringify: m,
      maxByteSize: function (t) {
        for (var e = 0, r = t.length, n = 0; n < r; n++) {
          var o = t.charCodeAt(n);
          o < 128 ? e += 1 : o < 2048 ? e += 2 : o < 65536 && (e += 3);
        }
        return e;
      },
      traverse: p,
      typeName: s,
      uuid4: h
    };
  }, function (t, e, r) {
    "use strict";

    r(17);
    var n = r(18),
      o = r(0);
    t.exports = {
      error: function () {
        var t = Array.prototype.slice.call(arguments, 0);
        t.unshift("Rollbar:"), n.ieVersion() <= 8 ? console.error(o.formatArgsAsString(t)) : console.error.apply(console, t);
      },
      info: function () {
        var t = Array.prototype.slice.call(arguments, 0);
        t.unshift("Rollbar:"), n.ieVersion() <= 8 ? console.info(o.formatArgsAsString(t)) : console.info.apply(console, t);
      },
      log: function () {
        var t = Array.prototype.slice.call(arguments, 0);
        t.unshift("Rollbar:"), n.ieVersion() <= 8 ? console.log(o.formatArgsAsString(t)) : console.log.apply(console, t);
      }
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(0);
    function o(t, e) {
      return [t, n.stringify(t, e)];
    }
    function i(t, e) {
      var r = t.length;
      return r > 2 * e ? t.slice(0, e).concat(t.slice(r - e)) : t;
    }
    function a(t, e, r) {
      r = void 0 === r ? 30 : r;
      var o,
        a = t.data.body;
      if (a.trace_chain) for (var s = a.trace_chain, u = 0; u < s.length; u++) o = i(o = s[u].frames, r), s[u].frames = o;else a.trace && (o = i(o = a.trace.frames, r), a.trace.frames = o);
      return [t, n.stringify(t, e)];
    }
    function s(t, e) {
      return e && e.length > t ? e.slice(0, t - 3).concat("...") : e;
    }
    function u(t, e, r) {
      return [e = n.traverse(e, function e(r, o, i) {
        switch (n.typeName(o)) {
          case "string":
            return s(t, o);
          case "object":
          case "array":
            return n.traverse(o, e, i);
          default:
            return o;
        }
      }, []), n.stringify(e, r)];
    }
    function c(t) {
      return t.exception && (delete t.exception.description, t.exception.message = s(255, t.exception.message)), t.frames = i(t.frames, 1), t;
    }
    function l(t, e) {
      var r = t.data.body;
      if (r.trace_chain) for (var o = r.trace_chain, i = 0; i < o.length; i++) o[i] = c(o[i]);else r.trace && (r.trace = c(r.trace));
      return [t, n.stringify(t, e)];
    }
    function p(t, e) {
      return n.maxByteSize(t) > e;
    }
    t.exports = {
      truncate: function (t, e, r) {
        r = void 0 === r ? 524288 : r;
        for (var n, i, s, c = [o, a, u.bind(null, 1024), u.bind(null, 512), u.bind(null, 256), l]; n = c.shift();) if (t = (i = n(t, e))[0], (s = i[1]).error || !p(s.value, r)) return s;
        return s;
      },
      raw: o,
      truncateFrames: a,
      truncateStrings: u,
      maybeTruncateValue: s
    };
  }, function (t, e, r) {
    "use strict";

    t.exports = {
      parse: function (t) {
        var e,
          r,
          n = {
            protocol: null,
            auth: null,
            host: null,
            path: null,
            hash: null,
            href: t,
            hostname: null,
            port: null,
            pathname: null,
            search: null,
            query: null
          };
        if (-1 !== (e = t.indexOf("//")) ? (n.protocol = t.substring(0, e), r = e + 2) : r = 0, -1 !== (e = t.indexOf("@", r)) && (n.auth = t.substring(r, e), r = e + 1), -1 === (e = t.indexOf("/", r))) {
          if (-1 === (e = t.indexOf("?", r))) return -1 === (e = t.indexOf("#", r)) ? n.host = t.substring(r) : (n.host = t.substring(r, e), n.hash = t.substring(e)), n.hostname = n.host.split(":")[0], n.port = n.host.split(":")[1], n.port && (n.port = parseInt(n.port, 10)), n;
          n.host = t.substring(r, e), n.hostname = n.host.split(":")[0], n.port = n.host.split(":")[1], n.port && (n.port = parseInt(n.port, 10)), r = e;
        } else n.host = t.substring(r, e), n.hostname = n.host.split(":")[0], n.port = n.host.split(":")[1], n.port && (n.port = parseInt(n.port, 10)), r = e;
        if (-1 === (e = t.indexOf("#", r)) ? n.path = t.substring(r) : (n.path = t.substring(r, e), n.hash = t.substring(e)), n.path) {
          var o = n.path.split("?");
          n.pathname = o[0], n.query = o[1], n.search = n.query ? "?" + n.query : null;
        }
        return n;
      }
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(22),
      o = new RegExp("^(([a-zA-Z0-9-_$ ]*): *)?(Uncaught )?([a-zA-Z0-9-_$ ]*): ");
    function i() {
      return null;
    }
    function a(t) {
      var e = {};
      return e._stackFrame = t, e.url = t.fileName, e.line = t.lineNumber, e.func = t.functionName, e.column = t.columnNumber, e.args = t.args, e.context = null, e;
    }
    function s(t, e) {
      return {
        stack: function () {
          var r = [];
          e = e || 0;
          try {
            r = n.parse(t);
          } catch (t) {
            r = [];
          }
          for (var o = [], i = e; i < r.length; i++) o.push(new a(r[i]));
          return o;
        }(),
        message: t.message,
        name: u(t),
        rawStack: t.stack,
        rawException: t
      };
    }
    function u(t) {
      var e = t.name && t.name.length && t.name,
        r = t.constructor.name && t.constructor.name.length && t.constructor.name;
      return e && r ? "Error" === e ? r : e : e || r;
    }
    t.exports = {
      guessFunctionName: function () {
        return "?";
      },
      guessErrorClass: function (t) {
        if (!t || !t.match) return ["Unknown error. There was no error message to display.", ""];
        var e = t.match(o),
          r = "(unknown)";
        return e && (r = e[e.length - 1], t = (t = t.replace((e[e.length - 2] || "") + r + ":", "")).replace(/(^[\s]+|[\s]+$)/g, "")), [r, t];
      },
      gatherContext: i,
      parse: function (t, e) {
        var r = t;
        if (r.nested) {
          for (var n = []; r;) n.push(new s(r, e)), r = r.nested, e = 0;
          return n[0].traceChain = n, n[0];
        }
        return new s(r, e);
      },
      Stack: s,
      Frame: a
    };
  }, function (t, e, r) {
    t.exports = r(6);
  }, function (t, e, r) {
    "use strict";

    var n = r(7),
      o = "undefined" != typeof window && window._rollbarConfig,
      i = o && o.globalAlias || "Rollbar",
      a = "undefined" != typeof window && window[i] && "function" == typeof window[i].shimId && void 0 !== window[i].shimId();
    if ("undefined" == typeof window || window._rollbarStartTime || (window._rollbarStartTime = new Date().getTime()), !a && o) {
      var s = new n(o);
      window[i] = s;
    } else "undefined" != typeof window ? (window.rollbar = n, window._rollbarDidLoad = !0) : "undefined" != typeof self && (self.rollbar = n, self._rollbarDidLoad = !0);
    t.exports = n;
  }, function (t, e, r) {
    "use strict";

    var n = r(8),
      o = r(0),
      i = r(15),
      a = r(1),
      s = r(19),
      u = r(20),
      c = r(3),
      l = r(21),
      p = r(24),
      f = r(25),
      h = r(26),
      d = r(4),
      m = r(27);
    function g(t, e) {
      this.options = o.handleOptions(_, t), this.options._configuredOptions = t;
      var r = new i(this.options, u, c);
      this.client = e || new n(this.options, r, a, "browser");
      var s = w(),
        d = "undefined" != typeof document && document;
      this.isChrome = s.chrome && s.chrome.runtime, this.anonymousErrorsPending = 0, function (t, e) {
        t.addTransform(l.handleDomException).addTransform(l.handleItemWithError).addTransform(l.ensureItemHasSomethingToSay).addTransform(l.addBaseInfo).addTransform(l.addRequestInfo(e)).addTransform(l.addClientInfo(e)).addTransform(l.addPluginInfo(e)).addTransform(l.addBody).addTransform(p.addMessageWithError).addTransform(p.addTelemetryData).addTransform(p.addConfigToPayload).addTransform(l.scrubPayload).addTransform(p.userTransform(a)).addTransform(p.addConfiguredOptions).addTransform(p.addDiagnosticKeys).addTransform(p.itemToPayload);
      }(this.client.notifier, s), this.client.queue.addPredicate(h.checkLevel).addPredicate(f.checkIgnore).addPredicate(h.userCheckIgnore(a)).addPredicate(h.urlIsNotBlacklisted(a)).addPredicate(h.urlIsWhitelisted(a)).addPredicate(h.messageIsIgnored(a)), this.setupUnhandledCapture(), this.instrumenter = new m(this.options, this.client.telemeter, this, s, d), this.instrumenter.instrument();
    }
    var v = null;
    function y(t) {
      var e = "Rollbar is not initialized";
      a.error(e), t && t(new Error(e));
    }
    function b(t) {
      for (var e = 0, r = t.length; e < r; ++e) if (o.isFunction(t[e])) return t[e];
    }
    function w() {
      return "undefined" != typeof window && window || "undefined" != typeof self && self;
    }
    g.init = function (t, e) {
      return v ? v.global(t).configure(t) : v = new g(t, e);
    }, g.prototype.global = function (t) {
      return this.client.global(t), this;
    }, g.global = function (t) {
      if (v) return v.global(t);
      y();
    }, g.prototype.configure = function (t, e) {
      var r = this.options,
        n = {};
      return e && (n = {
        payload: e
      }), this.options = o.handleOptions(r, t, n), this.options._configuredOptions = o.handleOptions(r._configuredOptions, t, n), this.client.configure(this.options, e), this.instrumenter.configure(this.options), this.setupUnhandledCapture(), this;
    }, g.configure = function (t, e) {
      if (v) return v.configure(t, e);
      y();
    }, g.prototype.lastError = function () {
      return this.client.lastError;
    }, g.lastError = function () {
      if (v) return v.lastError();
      y();
    }, g.prototype.log = function () {
      var t = this._createItem(arguments),
        e = t.uuid;
      return this.client.log(t), {
        uuid: e
      };
    }, g.log = function () {
      if (v) return v.log.apply(v, arguments);
      var t = b(arguments);
      y(t);
    }, g.prototype.debug = function () {
      var t = this._createItem(arguments),
        e = t.uuid;
      return this.client.debug(t), {
        uuid: e
      };
    }, g.debug = function () {
      if (v) return v.debug.apply(v, arguments);
      var t = b(arguments);
      y(t);
    }, g.prototype.info = function () {
      var t = this._createItem(arguments),
        e = t.uuid;
      return this.client.info(t), {
        uuid: e
      };
    }, g.info = function () {
      if (v) return v.info.apply(v, arguments);
      var t = b(arguments);
      y(t);
    }, g.prototype.warn = function () {
      var t = this._createItem(arguments),
        e = t.uuid;
      return this.client.warn(t), {
        uuid: e
      };
    }, g.warn = function () {
      if (v) return v.warn.apply(v, arguments);
      var t = b(arguments);
      y(t);
    }, g.prototype.warning = function () {
      var t = this._createItem(arguments),
        e = t.uuid;
      return this.client.warning(t), {
        uuid: e
      };
    }, g.warning = function () {
      if (v) return v.warning.apply(v, arguments);
      var t = b(arguments);
      y(t);
    }, g.prototype.error = function () {
      var t = this._createItem(arguments),
        e = t.uuid;
      return this.client.error(t), {
        uuid: e
      };
    }, g.error = function () {
      if (v) return v.error.apply(v, arguments);
      var t = b(arguments);
      y(t);
    }, g.prototype.critical = function () {
      var t = this._createItem(arguments),
        e = t.uuid;
      return this.client.critical(t), {
        uuid: e
      };
    }, g.critical = function () {
      if (v) return v.critical.apply(v, arguments);
      var t = b(arguments);
      y(t);
    }, g.prototype.buildJsonPayload = function (t) {
      return this.client.buildJsonPayload(t);
    }, g.buildJsonPayload = function () {
      if (v) return v.buildJsonPayload.apply(v, arguments);
      y();
    }, g.prototype.sendJsonPayload = function (t) {
      return this.client.sendJsonPayload(t);
    }, g.sendJsonPayload = function () {
      if (v) return v.sendJsonPayload.apply(v, arguments);
      y();
    }, g.prototype.setupUnhandledCapture = function () {
      var t = w();
      this.unhandledExceptionsInitialized || (this.options.captureUncaught || this.options.handleUncaughtExceptions) && (s.captureUncaughtExceptions(t, this), this.options.wrapGlobalEventHandlers && s.wrapGlobals(t, this), this.unhandledExceptionsInitialized = !0), this.unhandledRejectionsInitialized || (this.options.captureUnhandledRejections || this.options.handleUnhandledRejections) && (s.captureUnhandledRejections(t, this), this.unhandledRejectionsInitialized = !0);
    }, g.prototype.handleUncaughtException = function (t, e, r, n, i, a) {
      if (this.options.captureUncaught || this.options.handleUncaughtExceptions) {
        if (this.options.inspectAnonymousErrors && this.isChrome && null === i) return "anonymous";
        var s,
          u = o.makeUnhandledStackInfo(t, e, r, n, i, "onerror", "uncaught exception", d);
        o.isError(i) ? (s = this._createItem([t, i, a]))._unhandledStackInfo = u : o.isError(e) ? (s = this._createItem([t, e, a]))._unhandledStackInfo = u : (s = this._createItem([t, a])).stackInfo = u, s.level = this.options.uncaughtErrorLevel, s._isUncaught = !0, this.client.log(s);
      }
    }, g.prototype.handleAnonymousErrors = function () {
      if (this.options.inspectAnonymousErrors && this.isChrome) {
        var t = this;
        try {
          Error.prepareStackTrace = function (e, r) {
            if (t.options.inspectAnonymousErrors && t.anonymousErrorsPending) {
              if (t.anonymousErrorsPending -= 1, !e) return;
              e._isAnonymous = !0, t.handleUncaughtException(e.message, null, null, null, e);
            }
            return e.stack;
          };
        } catch (t) {
          this.options.inspectAnonymousErrors = !1, this.error("anonymous error handler failed", t);
        }
      }
    }, g.prototype.handleUnhandledRejection = function (t, e) {
      if (this.options.captureUnhandledRejections || this.options.handleUnhandledRejections) {
        var r = "unhandled rejection was null or undefined!";
        if (t) if (t.message) r = t.message;else {
          var n = o.stringify(t);
          n.value && (r = n.value);
        }
        var i,
          a = t && t._rollbarContext || e && e._rollbarContext;
        o.isError(t) ? i = this._createItem([r, t, a]) : (i = this._createItem([r, t, a])).stackInfo = o.makeUnhandledStackInfo(r, "", 0, 0, null, "unhandledrejection", "", d), i.level = this.options.uncaughtErrorLevel, i._isUncaught = !0, i._originalArgs = i._originalArgs || [], i._originalArgs.push(e), this.client.log(i);
      }
    }, g.prototype.wrap = function (t, e, r) {
      try {
        var n;
        if (n = o.isFunction(e) ? e : function () {
          return e || {};
        }, !o.isFunction(t)) return t;
        if (t._isWrap) return t;
        if (!t._rollbar_wrapped && (t._rollbar_wrapped = function () {
          r && o.isFunction(r) && r.apply(this, arguments);
          try {
            return t.apply(this, arguments);
          } catch (r) {
            var e = r;
            throw e && window._rollbarWrappedError !== e && (o.isType(e, "string") && (e = new String(e)), e._rollbarContext = n() || {}, e._rollbarContext._wrappedSource = t.toString(), window._rollbarWrappedError = e), e;
          }
        }, t._rollbar_wrapped._isWrap = !0, t.hasOwnProperty)) for (var i in t) t.hasOwnProperty(i) && "_rollbar_wrapped" !== i && (t._rollbar_wrapped[i] = t[i]);
        return t._rollbar_wrapped;
      } catch (e) {
        return t;
      }
    }, g.wrap = function (t, e) {
      if (v) return v.wrap(t, e);
      y();
    }, g.prototype.captureEvent = function () {
      var t = o.createTelemetryEvent(arguments);
      return this.client.captureEvent(t.type, t.metadata, t.level);
    }, g.captureEvent = function () {
      if (v) return v.captureEvent.apply(v, arguments);
      y();
    }, g.prototype.captureDomContentLoaded = function (t, e) {
      return e || (e = new Date()), this.client.captureDomContentLoaded(e);
    }, g.prototype.captureLoad = function (t, e) {
      return e || (e = new Date()), this.client.captureLoad(e);
    }, g.prototype._createItem = function (t) {
      return o.createItem(t, a, this);
    }, g.prototype.loadFull = function () {
      a.info("Unexpected Rollbar.loadFull() called on a Notifier instance. This can happen when Rollbar is loaded multiple times.");
    };
    var _ = {
      version: "2.18.0",
      scrubFields: ["pw", "pass", "passwd", "password", "secret", "confirm_password", "confirmPassword", "password_confirmation", "passwordConfirmation", "access_token", "accessToken", "X-Rollbar-Access-Token", "secret_key", "secretKey", "secretToken", "cc-number", "card number", "cardnumber", "cardnum", "ccnum", "ccnumber", "cc num", "creditcardnumber", "credit card number", "newcreditcardnumber", "new credit card", "creditcardno", "credit card no", "card#", "card #", "cc-csc", "cvc", "cvc2", "cvv2", "ccv2", "security code", "card verification", "name on credit card", "name on card", "nameoncard", "cardholder", "card holder", "name des karteninhabers", "ccname", "card type", "cardtype", "cc type", "cctype", "payment type", "expiration date", "expirationdate", "expdate", "cc-exp", "ccmonth", "ccyear"],
      logLevel: "debug",
      reportLevel: "debug",
      uncaughtErrorLevel: "error",
      endpoint: "api.rollbar.com/api/1/item/",
      verbose: !1,
      enabled: !0,
      transmit: !0,
      sendConfig: !1,
      includeItemsInTelemetry: !0,
      captureIp: !0,
      inspectAnonymousErrors: !0,
      ignoreDuplicateErrors: !0,
      wrapGlobalEventHandlers: !1
    };
    t.exports = g;
  }, function (t, e, r) {
    "use strict";

    var n = r(9),
      o = r(12),
      i = r(13),
      a = r(14),
      s = r(0);
    function u(t, e, r, n) {
      this.options = s.merge(t), this.logger = r, u.rateLimiter.configureGlobal(this.options), u.rateLimiter.setPlatformOptions(n, this.options), this.api = e, this.queue = new o(u.rateLimiter, e, r, this.options);
      var p = this.options.tracer || null;
      l(p) ? (this.tracer = p, this.options.tracer = "opentracing-tracer-enabled", this.options._configuredOptions.tracer = "opentracing-tracer-enabled") : this.tracer = null, this.notifier = new i(this.queue, this.options), this.telemeter = new a(this.options), c(t), this.lastError = null, this.lastErrorHash = "none";
    }
    function c(t) {
      t.stackTraceLimit && (Error.stackTraceLimit = t.stackTraceLimit);
    }
    function l(t) {
      if (!t) return !1;
      if (!t.scope || "function" != typeof t.scope) return !1;
      const e = t.scope();
      return !(!e || !e.active || "function" != typeof e.active);
    }
    u.rateLimiter = new n({
      maxItems: 0,
      itemsPerMinute: 60
    }), u.prototype.global = function (t) {
      return u.rateLimiter.configureGlobal(t), this;
    }, u.prototype.configure = function (t, e) {
      var r = this.options,
        n = {};
      e && (n = {
        payload: e
      }), this.options = s.merge(r, t, n);
      var o = this.options.tracer || null;
      return l(o) ? (this.tracer = o, this.options.tracer = "opentracing-tracer-enabled", this.options._configuredOptions.tracer = "opentracing-tracer-enabled") : this.tracer = null, this.notifier && this.notifier.configure(this.options), this.telemeter && this.telemeter.configure(this.options), c(t), this.global(this.options), l(t.tracer) && (this.tracer = t.tracer), this;
    }, u.prototype.log = function (t) {
      var e = this._defaultLogLevel();
      return this._log(e, t);
    }, u.prototype.debug = function (t) {
      this._log("debug", t);
    }, u.prototype.info = function (t) {
      this._log("info", t);
    }, u.prototype.warn = function (t) {
      this._log("warning", t);
    }, u.prototype.warning = function (t) {
      this._log("warning", t);
    }, u.prototype.error = function (t) {
      this._log("error", t);
    }, u.prototype.critical = function (t) {
      this._log("critical", t);
    }, u.prototype.wait = function (t) {
      this.queue.wait(t);
    }, u.prototype.captureEvent = function (t, e, r) {
      return this.telemeter.captureEvent(t, e, r);
    }, u.prototype.captureDomContentLoaded = function (t) {
      return this.telemeter.captureDomContentLoaded(t);
    }, u.prototype.captureLoad = function (t) {
      return this.telemeter.captureLoad(t);
    }, u.prototype.buildJsonPayload = function (t) {
      return this.api.buildJsonPayload(t);
    }, u.prototype.sendJsonPayload = function (t) {
      this.api.postJsonPayload(t);
    }, u.prototype._log = function (t, e) {
      var r;
      if (e.callback && (r = e.callback, delete e.callback), this.options.ignoreDuplicateErrors && this._sameAsLastError(e)) {
        if (r) {
          var n = new Error("ignored identical item");
          n.item = e, r(n);
        }
      } else try {
        this._addTracingInfo(e), e.level = e.level || t, this.telemeter._captureRollbarItem(e), e.telemetryEvents = this.telemeter.copyEvents(), this.notifier.log(e, r);
      } catch (t) {
        this.logger.error(t);
      }
    }, u.prototype._defaultLogLevel = function () {
      return this.options.logLevel || "debug";
    }, u.prototype._sameAsLastError = function (t) {
      if (!t._isUncaught) return !1;
      var e = function (t) {
        var e = t.message || "",
          r = (t.err || {}).stack || String(t.err);
        return e + "::" + r;
      }(t);
      return this.lastErrorHash === e || (this.lastError = t.err, this.lastErrorHash = e, !1);
    }, u.prototype._addTracingInfo = function (t) {
      if (this.tracer) {
        var e = this.tracer.scope().active();
        if (function (t) {
          if (!t || !t.context || "function" != typeof t.context) return !1;
          const e = t.context();
          if (!e || !e.toSpanId || !e.toTraceId || "function" != typeof e.toSpanId || "function" != typeof e.toTraceId) return !1;
          return !0;
        }(e)) {
          e.setTag("rollbar.error_uuid", t.uuid), e.setTag("rollbar.has_error", !0);
          var r = e.context().toSpanId(),
            n = e.context().toTraceId();
          t.custom ? (t.custom.opentracing_span_id = r, t.custom.opentracing_trace_id = n) : t.custom = {
            opentracing_span_id: r,
            opentracing_trace_id: n
          };
        }
      }
    }, t.exports = u;
  }, function (t, e, r) {
    "use strict";

    var n = r(0);
    function o(t) {
      this.startTime = n.now(), this.counter = 0, this.perMinCounter = 0, this.platform = null, this.platformOptions = {}, this.configureGlobal(t);
    }
    function i(t, e, r) {
      return !t.ignoreRateLimit && e >= 1 && r > e;
    }
    function a(t, e, r, n, o, i, a) {
      var s = null;
      return r && (r = new Error(r)), r || n || (s = function (t, e, r, n, o) {
        var i,
          a = e.environment || e.payload && e.payload.environment;
        i = o ? "item per minute limit reached, ignoring errors until timeout" : "maxItems has been hit, ignoring errors until reset.";
        var s = {
          body: {
            message: {
              body: i,
              extra: {
                maxItems: r,
                itemsPerMinute: n
              }
            }
          },
          language: "javascript",
          environment: a,
          notifier: {
            version: e.notifier && e.notifier.version || e.version
          }
        };
        "browser" === t ? (s.platform = "browser", s.framework = "browser-js", s.notifier.name = "rollbar-browser-js") : "server" === t ? (s.framework = e.framework || "node-js", s.notifier.name = e.notifier.name) : "react-native" === t && (s.framework = e.framework || "react-native", s.notifier.name = e.notifier.name);
        return s;
      }(t, e, o, i, a)), {
        error: r,
        shouldSend: n,
        payload: s
      };
    }
    o.globalSettings = {
      startTime: n.now(),
      maxItems: void 0,
      itemsPerMinute: void 0
    }, o.prototype.configureGlobal = function (t) {
      void 0 !== t.startTime && (o.globalSettings.startTime = t.startTime), void 0 !== t.maxItems && (o.globalSettings.maxItems = t.maxItems), void 0 !== t.itemsPerMinute && (o.globalSettings.itemsPerMinute = t.itemsPerMinute);
    }, o.prototype.shouldSend = function (t, e) {
      var r = (e = e || n.now()) - this.startTime;
      (r < 0 || r >= 6e4) && (this.startTime = e, this.perMinCounter = 0);
      var s = o.globalSettings.maxItems,
        u = o.globalSettings.itemsPerMinute;
      if (i(t, s, this.counter)) return a(this.platform, this.platformOptions, s + " max items reached", !1);
      if (i(t, u, this.perMinCounter)) return a(this.platform, this.platformOptions, u + " items per minute reached", !1);
      this.counter++, this.perMinCounter++;
      var c = !i(t, s, this.counter),
        l = c;
      return c = c && !i(t, u, this.perMinCounter), a(this.platform, this.platformOptions, null, c, s, u, l);
    }, o.prototype.setPlatformOptions = function (t, e) {
      this.platform = t, this.platformOptions = e;
    }, t.exports = o;
  }, function (t, e, r) {
    "use strict";

    var n = Object.prototype.hasOwnProperty,
      o = Object.prototype.toString,
      i = function (t) {
        if (!t || "[object Object]" !== o.call(t)) return !1;
        var e,
          r = n.call(t, "constructor"),
          i = t.constructor && t.constructor.prototype && n.call(t.constructor.prototype, "isPrototypeOf");
        if (t.constructor && !r && !i) return !1;
        for (e in t);
        return void 0 === e || n.call(t, e);
      };
    t.exports = function t() {
      var e,
        r,
        n,
        o,
        a,
        s = {},
        u = null,
        c = arguments.length;
      for (e = 0; e < c; e++) if (null != (u = arguments[e])) for (a in u) r = s[a], s !== (n = u[a]) && (n && i(n) ? (o = r && i(r) ? r : {}, s[a] = t(o, n)) : void 0 !== n && (s[a] = n));
      return s;
    };
  }, function (t, e) {
    t.exports = function (t) {
      var e,
        r,
        n,
        o,
        i,
        a,
        s,
        u,
        c,
        l,
        p,
        f,
        h,
        d = /[\\"\u0000-\u001f\u007f-\u009f\u00ad\u0600-\u0604\u070f\u17b4\u17b5\u200c-\u200f\u2028-\u202f\u2060-\u206f\ufeff\ufff0-\uffff]/g;
      function m(t) {
        return t < 10 ? "0" + t : t;
      }
      function g() {
        return this.valueOf();
      }
      function v(t) {
        return d.lastIndex = 0, d.test(t) ? '"' + t.replace(d, function (t) {
          var e = n[t];
          return "string" == typeof e ? e : "\\u" + ("0000" + t.charCodeAt(0).toString(16)).slice(-4);
        }) + '"' : '"' + t + '"';
      }
      "function" != typeof Date.prototype.toJSON && (Date.prototype.toJSON = function () {
        return isFinite(this.valueOf()) ? this.getUTCFullYear() + "-" + m(this.getUTCMonth() + 1) + "-" + m(this.getUTCDate()) + "T" + m(this.getUTCHours()) + ":" + m(this.getUTCMinutes()) + ":" + m(this.getUTCSeconds()) + "Z" : null;
      }, Boolean.prototype.toJSON = g, Number.prototype.toJSON = g, String.prototype.toJSON = g), "function" != typeof t.stringify && (n = {
        "\b": "\\b",
        "\t": "\\t",
        "\n": "\\n",
        "\f": "\\f",
        "\r": "\\r",
        '"': '\\"',
        "\\": "\\\\"
      }, t.stringify = function (t, n, i) {
        var a;
        if (e = "", r = "", "number" == typeof i) for (a = 0; a < i; a += 1) r += " ";else "string" == typeof i && (r = i);
        if (o = n, n && "function" != typeof n && ("object" != typeof n || "number" != typeof n.length)) throw new Error("JSON.stringify");
        return function t(n, i) {
          var a,
            s,
            u,
            c,
            l,
            p = e,
            f = i[n];
          switch (f && "object" == typeof f && "function" == typeof f.toJSON && (f = f.toJSON(n)), "function" == typeof o && (f = o.call(i, n, f)), typeof f) {
            case "string":
              return v(f);
            case "number":
              return isFinite(f) ? String(f) : "null";
            case "boolean":
            case "null":
              return String(f);
            case "object":
              if (!f) return "null";
              if (e += r, l = [], "[object Array]" === Object.prototype.toString.apply(f)) {
                for (c = f.length, a = 0; a < c; a += 1) l[a] = t(a, f) || "null";
                return u = 0 === l.length ? "[]" : e ? "[\n" + e + l.join(",\n" + e) + "\n" + p + "]" : "[" + l.join(",") + "]", e = p, u;
              }
              if (o && "object" == typeof o) for (c = o.length, a = 0; a < c; a += 1) "string" == typeof o[a] && (u = t(s = o[a], f)) && l.push(v(s) + (e ? ": " : ":") + u);else for (s in f) Object.prototype.hasOwnProperty.call(f, s) && (u = t(s, f)) && l.push(v(s) + (e ? ": " : ":") + u);
              return u = 0 === l.length ? "{}" : e ? "{\n" + e + l.join(",\n" + e) + "\n" + p + "}" : "{" + l.join(",") + "}", e = p, u;
          }
        }("", {
          "": t
        });
      }), "function" != typeof t.parse && (t.parse = (l = {
        "\\": "\\",
        '"': '"',
        "/": "/",
        t: "\t",
        n: "\n",
        r: "\r",
        f: "\f",
        b: "\b"
      }, p = {
        go: function () {
          i = "ok";
        },
        firstokey: function () {
          u = c, i = "colon";
        },
        okey: function () {
          u = c, i = "colon";
        },
        ovalue: function () {
          i = "ocomma";
        },
        firstavalue: function () {
          i = "acomma";
        },
        avalue: function () {
          i = "acomma";
        }
      }, f = {
        go: function () {
          i = "ok";
        },
        ovalue: function () {
          i = "ocomma";
        },
        firstavalue: function () {
          i = "acomma";
        },
        avalue: function () {
          i = "acomma";
        }
      }, h = {
        "{": {
          go: function () {
            a.push({
              state: "ok"
            }), s = {}, i = "firstokey";
          },
          ovalue: function () {
            a.push({
              container: s,
              state: "ocomma",
              key: u
            }), s = {}, i = "firstokey";
          },
          firstavalue: function () {
            a.push({
              container: s,
              state: "acomma"
            }), s = {}, i = "firstokey";
          },
          avalue: function () {
            a.push({
              container: s,
              state: "acomma"
            }), s = {}, i = "firstokey";
          }
        },
        "}": {
          firstokey: function () {
            var t = a.pop();
            c = s, s = t.container, u = t.key, i = t.state;
          },
          ocomma: function () {
            var t = a.pop();
            s[u] = c, c = s, s = t.container, u = t.key, i = t.state;
          }
        },
        "[": {
          go: function () {
            a.push({
              state: "ok"
            }), s = [], i = "firstavalue";
          },
          ovalue: function () {
            a.push({
              container: s,
              state: "ocomma",
              key: u
            }), s = [], i = "firstavalue";
          },
          firstavalue: function () {
            a.push({
              container: s,
              state: "acomma"
            }), s = [], i = "firstavalue";
          },
          avalue: function () {
            a.push({
              container: s,
              state: "acomma"
            }), s = [], i = "firstavalue";
          }
        },
        "]": {
          firstavalue: function () {
            var t = a.pop();
            c = s, s = t.container, u = t.key, i = t.state;
          },
          acomma: function () {
            var t = a.pop();
            s.push(c), c = s, s = t.container, u = t.key, i = t.state;
          }
        },
        ":": {
          colon: function () {
            if (Object.hasOwnProperty.call(s, u)) throw new SyntaxError("Duplicate key '" + u + '"');
            i = "ovalue";
          }
        },
        ",": {
          ocomma: function () {
            s[u] = c, i = "okey";
          },
          acomma: function () {
            s.push(c), i = "avalue";
          }
        },
        true: {
          go: function () {
            c = !0, i = "ok";
          },
          ovalue: function () {
            c = !0, i = "ocomma";
          },
          firstavalue: function () {
            c = !0, i = "acomma";
          },
          avalue: function () {
            c = !0, i = "acomma";
          }
        },
        false: {
          go: function () {
            c = !1, i = "ok";
          },
          ovalue: function () {
            c = !1, i = "ocomma";
          },
          firstavalue: function () {
            c = !1, i = "acomma";
          },
          avalue: function () {
            c = !1, i = "acomma";
          }
        },
        null: {
          go: function () {
            c = null, i = "ok";
          },
          ovalue: function () {
            c = null, i = "ocomma";
          },
          firstavalue: function () {
            c = null, i = "acomma";
          },
          avalue: function () {
            c = null, i = "acomma";
          }
        }
      }, function (t, e) {
        var r,
          n,
          o = /^[\u0020\t\n\r]*(?:([,:\[\]{}]|true|false|null)|(-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)|"((?:[^\r\n\t\\\"]|\\(?:["\\\/trnfb]|u[0-9a-fA-F]{4}))*)")/;
        i = "go", a = [];
        try {
          for (; r = o.exec(t);) r[1] ? h[r[1]][i]() : r[2] ? (c = +r[2], f[i]()) : (n = r[3], c = n.replace(/\\(?:u(.{4})|([^u]))/g, function (t, e, r) {
            return e ? String.fromCharCode(parseInt(e, 16)) : l[r];
          }), p[i]()), t = t.slice(r[0].length);
        } catch (t) {
          i = t;
        }
        if ("ok" !== i || /[^\u0020\t\n\r]/.test(t)) throw i instanceof SyntaxError ? i : new SyntaxError("JSON");
        return "function" == typeof e ? function t(r, n) {
          var o,
            i,
            a = r[n];
          if (a && "object" == typeof a) for (o in c) Object.prototype.hasOwnProperty.call(a, o) && (void 0 !== (i = t(a, o)) ? a[o] = i : delete a[o]);
          return e.call(r, n, a);
        }({
          "": c
        }, "") : c;
      }));
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(0);
    function o(t, e, r, n) {
      this.rateLimiter = t, this.api = e, this.logger = r, this.options = n, this.predicates = [], this.pendingItems = [], this.pendingRequests = [], this.retryQueue = [], this.retryHandle = null, this.waitCallback = null, this.waitIntervalID = null;
    }
    o.prototype.configure = function (t) {
      this.api && this.api.configure(t);
      var e = this.options;
      return this.options = n.merge(e, t), this;
    }, o.prototype.addPredicate = function (t) {
      return n.isFunction(t) && this.predicates.push(t), this;
    }, o.prototype.addPendingItem = function (t) {
      this.pendingItems.push(t);
    }, o.prototype.removePendingItem = function (t) {
      var e = this.pendingItems.indexOf(t);
      -1 !== e && this.pendingItems.splice(e, 1);
    }, o.prototype.addItem = function (t, e, r, o) {
      e && n.isFunction(e) || (e = function () {});
      var i = this._applyPredicates(t);
      if (i.stop) return this.removePendingItem(o), void e(i.err);
      if (this._maybeLog(t, r), this.removePendingItem(o), this.options.transmit) {
        this.pendingRequests.push(t);
        try {
          this._makeApiRequest(t, function (r, n) {
            this._dequeuePendingRequest(t), e(r, n);
          }.bind(this));
        } catch (r) {
          this._dequeuePendingRequest(t), e(r);
        }
      } else e(new Error("Transmit disabled"));
    }, o.prototype.wait = function (t) {
      n.isFunction(t) && (this.waitCallback = t, this._maybeCallWait() || (this.waitIntervalID && (this.waitIntervalID = clearInterval(this.waitIntervalID)), this.waitIntervalID = setInterval(function () {
        this._maybeCallWait();
      }.bind(this), 500)));
    }, o.prototype._applyPredicates = function (t) {
      for (var e = null, r = 0, n = this.predicates.length; r < n; r++) if (!(e = this.predicates[r](t, this.options)) || void 0 !== e.err) return {
        stop: !0,
        err: e.err
      };
      return {
        stop: !1,
        err: null
      };
    }, o.prototype._makeApiRequest = function (t, e) {
      var r = this.rateLimiter.shouldSend(t);
      r.shouldSend ? this.api.postItem(t, function (r, n) {
        r ? this._maybeRetry(r, t, e) : e(r, n);
      }.bind(this)) : r.error ? e(r.error) : this.api.postItem(r.payload, e);
    };
    var i = ["ECONNRESET", "ENOTFOUND", "ESOCKETTIMEDOUT", "ETIMEDOUT", "ECONNREFUSED", "EHOSTUNREACH", "EPIPE", "EAI_AGAIN"];
    o.prototype._maybeRetry = function (t, e, r) {
      var n = !1;
      if (this.options.retryInterval) for (var o = 0, a = i.length; o < a; o++) if (t.code === i[o]) {
        n = !0;
        break;
      }
      n ? this._retryApiRequest(e, r) : r(t);
    }, o.prototype._retryApiRequest = function (t, e) {
      this.retryQueue.push({
        item: t,
        callback: e
      }), this.retryHandle || (this.retryHandle = setInterval(function () {
        for (; this.retryQueue.length;) {
          var t = this.retryQueue.shift();
          this._makeApiRequest(t.item, t.callback);
        }
      }.bind(this), this.options.retryInterval));
    }, o.prototype._dequeuePendingRequest = function (t) {
      var e = this.pendingRequests.indexOf(t);
      -1 !== e && (this.pendingRequests.splice(e, 1), this._maybeCallWait());
    }, o.prototype._maybeLog = function (t, e) {
      if (this.logger && this.options.verbose) {
        var r = e;
        if (r = (r = r || n.get(t, "body.trace.exception.message")) || n.get(t, "body.trace_chain.0.exception.message")) return void this.logger.error(r);
        (r = n.get(t, "body.message.body")) && this.logger.log(r);
      }
    }, o.prototype._maybeCallWait = function () {
      return !(!n.isFunction(this.waitCallback) || 0 !== this.pendingItems.length || 0 !== this.pendingRequests.length) && (this.waitIntervalID && (this.waitIntervalID = clearInterval(this.waitIntervalID)), this.waitCallback(), !0);
    }, t.exports = o;
  }, function (t, e, r) {
    "use strict";

    var n = r(0);
    function o(t, e) {
      this.queue = t, this.options = e, this.transforms = [], this.diagnostic = {};
    }
    o.prototype.configure = function (t) {
      this.queue && this.queue.configure(t);
      var e = this.options;
      return this.options = n.merge(e, t), this;
    }, o.prototype.addTransform = function (t) {
      return n.isFunction(t) && this.transforms.push(t), this;
    }, o.prototype.log = function (t, e) {
      if (e && n.isFunction(e) || (e = function () {}), !this.options.enabled) return e(new Error("Rollbar is not enabled"));
      this.queue.addPendingItem(t);
      var r = t.err;
      this._applyTransforms(t, function (n, o) {
        if (n) return this.queue.removePendingItem(t), e(n, null);
        this.queue.addItem(o, e, r, t);
      }.bind(this));
    }, o.prototype._applyTransforms = function (t, e) {
      var r = -1,
        n = this.transforms.length,
        o = this.transforms,
        i = this.options,
        a = function (t, s) {
          t ? e(t, null) : ++r !== n ? o[r](s, i, a) : e(null, s);
        };
      a(null, t);
    }, t.exports = o;
  }, function (t, e, r) {
    "use strict";

    var n = r(0);
    function o(t) {
      this.queue = [], this.options = n.merge(t);
      var e = this.options.maxTelemetryEvents || 100;
      this.maxQueueSize = Math.max(0, Math.min(e, 100));
    }
    function i(t, e) {
      if (e) return e;
      return {
        error: "error",
        manual: "info"
      }[t] || "info";
    }
    o.prototype.configure = function (t) {
      var e = this.options;
      this.options = n.merge(e, t);
      var r = this.options.maxTelemetryEvents || 100,
        o = Math.max(0, Math.min(r, 100)),
        i = 0;
      this.maxQueueSize > o && (i = this.maxQueueSize - o), this.maxQueueSize = o, this.queue.splice(0, i);
    }, o.prototype.copyEvents = function () {
      var t = Array.prototype.slice.call(this.queue, 0);
      if (n.isFunction(this.options.filterTelemetry)) try {
        for (var e = t.length; e--;) this.options.filterTelemetry(t[e]) && t.splice(e, 1);
      } catch (t) {
        this.options.filterTelemetry = null;
      }
      return t;
    }, o.prototype.capture = function (t, e, r, o, a) {
      var s = {
        level: i(t, r),
        type: t,
        timestamp_ms: a || n.now(),
        body: e,
        source: "client"
      };
      o && (s.uuid = o);
      try {
        if (n.isFunction(this.options.filterTelemetry) && this.options.filterTelemetry(s)) return !1;
      } catch (t) {
        this.options.filterTelemetry = null;
      }
      return this.push(s), s;
    }, o.prototype.captureEvent = function (t, e, r, n) {
      return this.capture(t, e, r, n);
    }, o.prototype.captureError = function (t, e, r, n) {
      var o = {
        message: t.message || String(t)
      };
      return t.stack && (o.stack = t.stack), this.capture("error", o, e, r, n);
    }, o.prototype.captureLog = function (t, e, r, n) {
      return this.capture("log", {
        message: t
      }, e, r, n);
    }, o.prototype.captureNetwork = function (t, e, r, n) {
      e = e || "xhr", t.subtype = t.subtype || e, n && (t.request = n);
      var o = this.levelFromStatus(t.status_code);
      return this.capture("network", t, o, r);
    }, o.prototype.levelFromStatus = function (t) {
      return t >= 200 && t < 400 ? "info" : 0 === t || t >= 400 ? "error" : "info";
    }, o.prototype.captureDom = function (t, e, r, n, o) {
      var i = {
        subtype: t,
        element: e
      };
      return void 0 !== r && (i.value = r), void 0 !== n && (i.checked = n), this.capture("dom", i, "info", o);
    }, o.prototype.captureNavigation = function (t, e, r) {
      return this.capture("navigation", {
        from: t,
        to: e
      }, "info", r);
    }, o.prototype.captureDomContentLoaded = function (t) {
      return this.capture("navigation", {
        subtype: "DOMContentLoaded"
      }, "info", void 0, t && t.getTime());
    }, o.prototype.captureLoad = function (t) {
      return this.capture("navigation", {
        subtype: "load"
      }, "info", void 0, t && t.getTime());
    }, o.prototype.captureConnectivityChange = function (t, e) {
      return this.captureNetwork({
        change: t
      }, "connectivity", e);
    }, o.prototype._captureRollbarItem = function (t) {
      if (this.options.includeItemsInTelemetry) return t.err ? this.captureError(t.err, t.level, t.uuid, t.timestamp) : t.message ? this.captureLog(t.message, t.level, t.uuid, t.timestamp) : t.custom ? this.capture("log", t.custom, t.level, t.uuid, t.timestamp) : void 0;
    }, o.prototype.push = function (t) {
      this.queue.push(t), this.queue.length > this.maxQueueSize && this.queue.shift();
    }, t.exports = o;
  }, function (t, e, r) {
    "use strict";

    var n = r(0),
      o = r(16),
      i = r(2),
      a = {
        hostname: "api.rollbar.com",
        path: "/api/1/item/",
        search: null,
        version: "1",
        protocol: "https:",
        port: 443
      };
    function s(t, e, r, n) {
      this.options = t, this.transport = e, this.url = r, this.jsonBackup = n, this.accessToken = t.accessToken, this.transportOptions = u(t, r);
    }
    function u(t, e) {
      return o.getTransportFromOptions(t, a, e);
    }
    s.prototype.postItem = function (t, e) {
      var r = o.transportOptions(this.transportOptions, "POST"),
        n = o.buildPayload(this.accessToken, t, this.jsonBackup);
      this.transport.post(this.accessToken, r, n, e);
    }, s.prototype.buildJsonPayload = function (t, e) {
      var r = o.buildPayload(this.accessToken, t, this.jsonBackup),
        n = i.truncate(r);
      return n.error ? (e && e(n.error), null) : n.value;
    }, s.prototype.postJsonPayload = function (t, e) {
      var r = o.transportOptions(this.transportOptions, "POST");
      this.transport.postJsonPayload(this.accessToken, r, t, e);
    }, s.prototype.configure = function (t) {
      var e = this.oldOptions;
      return this.options = n.merge(e, t), this.transportOptions = u(this.options, this.url), void 0 !== this.options.accessToken && (this.accessToken = this.options.accessToken), this;
    }, t.exports = s;
  }, function (t, e, r) {
    "use strict";

    var n = r(0);
    t.exports = {
      buildPayload: function (t, e, r) {
        if (!n.isType(e.context, "string")) {
          var o = n.stringify(e.context, r);
          o.error ? e.context = "Error: could not serialize 'context'" : e.context = o.value || "", e.context.length > 255 && (e.context = e.context.substr(0, 255));
        }
        return {
          access_token: t,
          data: e
        };
      },
      getTransportFromOptions: function (t, e, r) {
        var n = e.hostname,
          o = e.protocol,
          i = e.port,
          a = e.path,
          s = e.search,
          u = t.proxy;
        if (t.endpoint) {
          var c = r.parse(t.endpoint);
          n = c.hostname, o = c.protocol, i = c.port, a = c.pathname, s = c.search;
        }
        return {
          hostname: n,
          protocol: o,
          port: i,
          path: a,
          search: s,
          proxy: u
        };
      },
      transportOptions: function (t, e) {
        var r = t.protocol || "https:",
          n = t.port || ("http:" === r ? 80 : "https:" === r ? 443 : void 0),
          o = t.hostname,
          i = t.path;
        return t.search && (i += t.search), t.proxy && (i = r + "//" + o + i, o = t.proxy.host || t.proxy.hostname, n = t.proxy.port, r = t.proxy.protocol || r), {
          protocol: r,
          hostname: o,
          path: i,
          port: n,
          method: e
        };
      },
      appendPathToPath: function (t, e) {
        var r = /\/$/.test(t),
          n = /^\//.test(e);
        return r && n ? e = e.substring(1) : r || n || (e = "/" + e), t + e;
      }
    };
  }, function (t, e) {
    !function (t) {
      "use strict";

      t.console || (t.console = {});
      for (var e, r, n = t.console, o = function () {}, i = ["memory"], a = "assert,clear,count,debug,dir,dirxml,error,exception,group,groupCollapsed,groupEnd,info,log,markTimeline,profile,profiles,profileEnd,show,table,time,timeEnd,timeline,timelineEnd,timeStamp,trace,warn".split(","); e = i.pop();) n[e] || (n[e] = {});
      for (; r = a.pop();) n[r] || (n[r] = o);
    }("undefined" == typeof window ? this : window);
  }, function (t, e, r) {
    "use strict";

    var n = {
      ieVersion: function () {
        if ("undefined" != typeof document) {
          for (var t = 3, e = document.createElement("div"), r = e.getElementsByTagName("i"); e.innerHTML = "\x3c!--[if gt IE " + ++t + "]><i></i><![endif]--\x3e", r[0];);
          return t > 4 ? t : void 0;
        }
      }
    };
    t.exports = n;
  }, function (t, e, r) {
    "use strict";

    function n(t, e, r, n) {
      t._rollbarWrappedError && (n[4] || (n[4] = t._rollbarWrappedError), n[5] || (n[5] = t._rollbarWrappedError._rollbarContext), t._rollbarWrappedError = null);
      var o = e.handleUncaughtException.apply(e, n);
      r && r.apply(t, n), "anonymous" === o && (e.anonymousErrorsPending += 1);
    }
    function o(t, e, r) {
      if (e.hasOwnProperty && e.hasOwnProperty("addEventListener")) {
        for (var n = e.addEventListener; n._rollbarOldAdd && n.belongsToShim;) n = n._rollbarOldAdd;
        var o = function (e, r, o) {
          n.call(this, e, t.wrap(r), o);
        };
        o._rollbarOldAdd = n, o.belongsToShim = r, e.addEventListener = o;
        for (var i = e.removeEventListener; i._rollbarOldRemove && i.belongsToShim;) i = i._rollbarOldRemove;
        var a = function (t, e, r) {
          i.call(this, t, e && e._rollbar_wrapped || e, r);
        };
        a._rollbarOldRemove = i, a.belongsToShim = r, e.removeEventListener = a;
      }
    }
    t.exports = {
      captureUncaughtExceptions: function (t, e, r) {
        if (t) {
          var o;
          if ("function" == typeof e._rollbarOldOnError) o = e._rollbarOldOnError;else if (t.onerror) {
            for (o = t.onerror; o._rollbarOldOnError;) o = o._rollbarOldOnError;
            e._rollbarOldOnError = o;
          }
          e.handleAnonymousErrors();
          var i = function () {
            var r = Array.prototype.slice.call(arguments, 0);
            n(t, e, o, r);
          };
          r && (i._rollbarOldOnError = o), t.onerror = i;
        }
      },
      captureUnhandledRejections: function (t, e, r) {
        if (t) {
          "function" == typeof t._rollbarURH && t._rollbarURH.belongsToShim && t.removeEventListener("unhandledrejection", t._rollbarURH);
          var n = function (t) {
            var r, n, o;
            try {
              r = t.reason;
            } catch (t) {
              r = void 0;
            }
            try {
              n = t.promise;
            } catch (t) {
              n = "[unhandledrejection] error getting `promise` from event";
            }
            try {
              o = t.detail, !r && o && (r = o.reason, n = o.promise);
            } catch (t) {}
            r || (r = "[unhandledrejection] error getting `reason` from event"), e && e.handleUnhandledRejection && e.handleUnhandledRejection(r, n);
          };
          n.belongsToShim = r, t._rollbarURH = n, t.addEventListener("unhandledrejection", n);
        }
      },
      wrapGlobals: function (t, e, r) {
        if (t) {
          var n,
            i,
            a = "EventTarget,Window,Node,ApplicationCache,AudioTrackList,ChannelMergerNode,CryptoOperation,EventSource,FileReader,HTMLUnknownElement,IDBDatabase,IDBRequest,IDBTransaction,KeyOperation,MediaController,MessagePort,ModalWindow,Notification,SVGElementInstance,Screen,TextTrack,TextTrackCue,TextTrackList,WebSocket,WebSocketWorker,Worker,XMLHttpRequest,XMLHttpRequestEventTarget,XMLHttpRequestUpload".split(",");
          for (n = 0; n < a.length; ++n) t[i = a[n]] && t[i].prototype && o(e, t[i].prototype, r);
        }
      }
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(0),
      o = r(2),
      i = r(1);
    function a(t, e, r, n, o, i) {
      var a = "undefined" != typeof window && window || "undefined" != typeof self && self,
        u = a && a.Zone && a.Zone.current;
      u && "angular" === u._name ? u._parent.run(function () {
        s(t, e, r, n, o, i);
      }) : s(t, e, r, n, o, i);
    }
    function s(t, e, r, o, a, s) {
      if ("undefined" != typeof RollbarProxy) return function (t, e) {
        new RollbarProxy().sendJsonPayload(t, function (t) {}, function (t) {
          e(new Error(t));
        });
      }(o, a);
      var c;
      if (!(c = s ? s() : function () {
        var t,
          e,
          r = [function () {
            return new XMLHttpRequest();
          }, function () {
            return new ActiveXObject("Msxml2.XMLHTTP");
          }, function () {
            return new ActiveXObject("Msxml3.XMLHTTP");
          }, function () {
            return new ActiveXObject("Microsoft.XMLHTTP");
          }],
          n = r.length;
        for (e = 0; e < n; e++) try {
          t = r[e]();
          break;
        } catch (t) {}
        return t;
      }())) return a(new Error("No way to send a request"));
      try {
        try {
          var l = function () {
            try {
              if (l && 4 === c.readyState) {
                l = void 0;
                var t = n.jsonParse(c.responseText);
                if ((o = c) && o.status && 200 === o.status) return void a(t.error, t.value);
                if (function (t) {
                  return t && n.isType(t.status, "number") && t.status >= 400 && t.status < 600;
                }(c)) {
                  if (403 === c.status) {
                    var e = t.value && t.value.message;
                    i.error(e);
                  }
                  a(new Error(String(c.status)));
                } else {
                  a(u("XHR response had no status code (likely connection failure)"));
                }
              }
            } catch (t) {
              var r;
              r = t && t.stack ? t : new Error(t), a(r);
            }
            var o;
          };
          c.open(r, e, !0), c.setRequestHeader && (c.setRequestHeader("Content-Type", "application/json"), c.setRequestHeader("X-Rollbar-Access-Token", t)), c.onreadystatechange = l, c.send(o);
        } catch (t) {
          if ("undefined" != typeof XDomainRequest) {
            if (!window || !window.location) return a(new Error("No window available during request, unknown environment"));
            "http:" === window.location.href.substring(0, 5) && "https" === e.substring(0, 5) && (e = "http" + e.substring(5));
            var p = new XDomainRequest();
            p.onprogress = function () {}, p.ontimeout = function () {
              a(u("Request timed out", "ETIMEDOUT"));
            }, p.onerror = function () {
              a(new Error("Error during request"));
            }, p.onload = function () {
              var t = n.jsonParse(p.responseText);
              a(t.error, t.value);
            }, p.open(r, e, !0), p.send(o);
          } else a(new Error("Cannot find a method to transport a request"));
        }
      } catch (t) {
        a(t);
      }
    }
    function u(t, e) {
      var r = new Error(t);
      return r.code = e || "ENOTFOUND", r;
    }
    t.exports = {
      get: function (t, e, r, o, i) {
        o && n.isFunction(o) || (o = function () {}), n.addParamsAndAccessTokenToPath(t, e, r), a(t, n.formatUrl(e), "GET", null, o, i);
      },
      post: function (t, e, r, i, s) {
        if (i && n.isFunction(i) || (i = function () {}), !r) return i(new Error("Cannot send empty request"));
        var u = o.truncate(r);
        if (u.error) return i(u.error);
        var c = u.value;
        a(t, n.formatUrl(e), "POST", c, i, s);
      },
      postJsonPayload: function (t, e, r, o, i) {
        o && n.isFunction(o) || (o = function () {}), a(t, n.formatUrl(e), "POST", r, o, i);
      }
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(0),
      o = r(4),
      i = r(1);
    function a(t, e, r) {
      var o = t.message,
        i = t.custom;
      o || (o = "Item sent with null or missing arguments.");
      var a = {
        body: o
      };
      i && (a.extra = n.merge(i)), n.set(t, "data.body", {
        message: a
      }), r(null, t);
    }
    function s(t) {
      var e = t.stackInfo.stack;
      return e && 0 === e.length && t._unhandledStackInfo && t._unhandledStackInfo.stack && (e = t._unhandledStackInfo.stack), e;
    }
    function u(t, e, r) {
      var i = t && t.data.description,
        a = t && t.custom,
        u = s(t),
        l = o.guessErrorClass(e.message),
        p = {
          exception: {
            class: c(e, l[0], r),
            message: l[1]
          }
        };
      if (i && (p.exception.description = i), u) {
        var f, h, d, m, g, v, y, b;
        for (0 === u.length && (p.exception.stack = e.rawStack, p.exception.raw = String(e.rawException)), p.frames = [], y = 0; y < u.length; ++y) h = {
          filename: (f = u[y]).url ? n.sanitizeUrl(f.url) : "(unknown)",
          lineno: f.line || null,
          method: f.func && "?" !== f.func ? f.func : "[anonymous]",
          colno: f.column
        }, r.sendFrameUrl && (h.url = f.url), h.method && h.method.endsWith && h.method.endsWith("_rollbar_wrapped") || (d = m = g = null, (v = f.context ? f.context.length : 0) && (b = Math.floor(v / 2), m = f.context.slice(0, b), d = f.context[b], g = f.context.slice(b)), d && (h.code = d), (m || g) && (h.context = {}, m && m.length && (h.context.pre = m), g && g.length && (h.context.post = g)), f.args && (h.args = f.args), p.frames.push(h));
        p.frames.reverse(), a && (p.extra = n.merge(a));
      }
      return p;
    }
    function c(t, e, r) {
      return t.name ? t.name : r.guessErrorClass ? e : "(unknown)";
    }
    t.exports = {
      handleDomException: function (t, e, r) {
        if (t.err && "DOMException" === o.Stack(t.err).name) {
          var n = new Error();
          n.name = t.err.name, n.message = t.err.message, n.stack = t.err.stack, n.nested = t.err, t.err = n;
        }
        r(null, t);
      },
      handleItemWithError: function (t, e, r) {
        if (t.data = t.data || {}, t.err) try {
          t.stackInfo = t.err._savedStackTrace || o.parse(t.err, t.skipFrames), e.addErrorContext && function (t) {
            var e = [],
              r = t.err;
            e.push(r);
            for (; r.nested;) r = r.nested, e.push(r);
            n.addErrorContext(t, e);
          }(t);
        } catch (e) {
          i.error("Error while parsing the error object.", e);
          try {
            t.message = t.err.message || t.err.description || t.message || String(t.err);
          } catch (e) {
            t.message = String(t.err) || String(e);
          }
          delete t.err;
        }
        r(null, t);
      },
      ensureItemHasSomethingToSay: function (t, e, r) {
        t.message || t.stackInfo || t.custom || r(new Error("No message, stack info, or custom data"), null), r(null, t);
      },
      addBaseInfo: function (t, e, r) {
        var o = e.payload && e.payload.environment || e.environment;
        t.data = n.merge(t.data, {
          environment: o,
          level: t.level,
          endpoint: e.endpoint,
          platform: "browser",
          framework: "browser-js",
          language: "javascript",
          server: {},
          uuid: t.uuid,
          notifier: {
            name: "rollbar-browser-js",
            version: e.version
          },
          custom: t.custom
        }), r(null, t);
      },
      addRequestInfo: function (t) {
        return function (e, r, o) {
          if (!t || !t.location) return o(null, e);
          var i = "$remote_ip";
          r.captureIp ? !0 !== r.captureIp && (i += "_anonymize") : i = null, n.set(e, "data.request", {
            url: t.location.href,
            query_string: t.location.search,
            user_ip: i
          }), o(null, e);
        };
      },
      addClientInfo: function (t) {
        return function (e, r, o) {
          if (!t) return o(null, e);
          var i = t.navigator || {},
            a = t.screen || {};
          n.set(e, "data.client", {
            runtime_ms: e.timestamp - t._rollbarStartTime,
            timestamp: Math.round(e.timestamp / 1e3),
            javascript: {
              browser: i.userAgent,
              language: i.language,
              cookie_enabled: i.cookieEnabled,
              screen: {
                width: a.width,
                height: a.height
              }
            }
          }), o(null, e);
        };
      },
      addPluginInfo: function (t) {
        return function (e, r, o) {
          if (!t || !t.navigator) return o(null, e);
          for (var i, a = [], s = t.navigator.plugins || [], u = 0, c = s.length; u < c; ++u) i = s[u], a.push({
            name: i.name,
            description: i.description
          });
          n.set(e, "data.client.javascript.plugins", a), o(null, e);
        };
      },
      addBody: function (t, e, r) {
        t.stackInfo ? t.stackInfo.traceChain ? function (t, e, r) {
          for (var o = t.stackInfo.traceChain, i = [], a = o.length, s = 0; s < a; s++) {
            var c = u(t, o[s], e);
            i.push(c);
          }
          n.set(t, "data.body", {
            trace_chain: i
          }), r(null, t);
        }(t, e, r) : function (t, e, r) {
          if (s(t)) {
            var i = u(t, t.stackInfo, e);
            n.set(t, "data.body", {
              trace: i
            }), r(null, t);
          } else {
            var l = t.stackInfo,
              p = o.guessErrorClass(l.message),
              f = c(l, p[0], e),
              h = p[1];
            t.message = f + ": " + h, a(t, e, r);
          }
        }(t, e, r) : a(t, e, r);
      },
      scrubPayload: function (t, e, r) {
        var o = e.scrubFields || [],
          i = e.scrubPaths || [];
        t.data = n.scrub(t.data, o, i), r(null, t);
      }
    };
  }, function (t, e, r) {
    var n, o, i;
    !function (a, s) {
      "use strict";

      o = [r(23)], void 0 === (i = "function" == typeof (n = function (t) {
        var e = /(^|@)\S+:\d+/,
          r = /^\s*at .*(\S+:\d+|\(native\))/m,
          n = /^(eval@)?(\[native code])?$/;
        return {
          parse: function (t) {
            if (void 0 !== t.stacktrace || void 0 !== t["opera#sourceloc"]) return this.parseOpera(t);
            if (t.stack && t.stack.match(r)) return this.parseV8OrIE(t);
            if (t.stack) return this.parseFFOrSafari(t);
            throw new Error("Cannot parse given Error object");
          },
          extractLocation: function (t) {
            if (-1 === t.indexOf(":")) return [t];
            var e = /(.+?)(?::(\d+))?(?::(\d+))?$/.exec(t.replace(/[()]/g, ""));
            return [e[1], e[2] || void 0, e[3] || void 0];
          },
          parseV8OrIE: function (e) {
            return e.stack.split("\n").filter(function (t) {
              return !!t.match(r);
            }, this).map(function (e) {
              e.indexOf("(eval ") > -1 && (e = e.replace(/eval code/g, "eval").replace(/(\(eval at [^()]*)|(\),.*$)/g, ""));
              var r = e.replace(/^\s+/, "").replace(/\(eval code/g, "("),
                n = r.match(/ (\((.+):(\d+):(\d+)\)$)/),
                o = (r = n ? r.replace(n[0], "") : r).split(/\s+/).slice(1),
                i = this.extractLocation(n ? n[1] : o.pop()),
                a = o.join(" ") || void 0,
                s = ["eval", "<anonymous>"].indexOf(i[0]) > -1 ? void 0 : i[0];
              return new t({
                functionName: a,
                fileName: s,
                lineNumber: i[1],
                columnNumber: i[2],
                source: e
              });
            }, this);
          },
          parseFFOrSafari: function (e) {
            return e.stack.split("\n").filter(function (t) {
              return !t.match(n);
            }, this).map(function (e) {
              if (e.indexOf(" > eval") > -1 && (e = e.replace(/ line (\d+)(?: > eval line \d+)* > eval:\d+:\d+/g, ":$1")), -1 === e.indexOf("@") && -1 === e.indexOf(":")) return new t({
                functionName: e
              });
              var r = /((.*".+"[^@]*)?[^@]*)(?:@)/,
                n = e.match(r),
                o = n && n[1] ? n[1] : void 0,
                i = this.extractLocation(e.replace(r, ""));
              return new t({
                functionName: o,
                fileName: i[0],
                lineNumber: i[1],
                columnNumber: i[2],
                source: e
              });
            }, this);
          },
          parseOpera: function (t) {
            return !t.stacktrace || t.message.indexOf("\n") > -1 && t.message.split("\n").length > t.stacktrace.split("\n").length ? this.parseOpera9(t) : t.stack ? this.parseOpera11(t) : this.parseOpera10(t);
          },
          parseOpera9: function (e) {
            for (var r = /Line (\d+).*script (?:in )?(\S+)/i, n = e.message.split("\n"), o = [], i = 2, a = n.length; i < a; i += 2) {
              var s = r.exec(n[i]);
              s && o.push(new t({
                fileName: s[2],
                lineNumber: s[1],
                source: n[i]
              }));
            }
            return o;
          },
          parseOpera10: function (e) {
            for (var r = /Line (\d+).*script (?:in )?(\S+)(?:: In function (\S+))?$/i, n = e.stacktrace.split("\n"), o = [], i = 0, a = n.length; i < a; i += 2) {
              var s = r.exec(n[i]);
              s && o.push(new t({
                functionName: s[3] || void 0,
                fileName: s[2],
                lineNumber: s[1],
                source: n[i]
              }));
            }
            return o;
          },
          parseOpera11: function (r) {
            return r.stack.split("\n").filter(function (t) {
              return !!t.match(e) && !t.match(/^Error created at/);
            }, this).map(function (e) {
              var r,
                n = e.split("@"),
                o = this.extractLocation(n.pop()),
                i = n.shift() || "",
                a = i.replace(/<anonymous function(: (\w+))?>/, "$2").replace(/\([^)]*\)/g, "") || void 0;
              i.match(/\(([^)]*)\)/) && (r = i.replace(/^[^(]+\(([^)]*)\)$/, "$1"));
              var s = void 0 === r || "[arguments not available]" === r ? void 0 : r.split(",");
              return new t({
                functionName: a,
                args: s,
                fileName: o[0],
                lineNumber: o[1],
                columnNumber: o[2],
                source: e
              });
            }, this);
          }
        };
      }) ? n.apply(e, o) : n) || (t.exports = i);
    }();
  }, function (t, e, r) {
    var n, o, i;
    !function (r, a) {
      "use strict";

      o = [], void 0 === (i = "function" == typeof (n = function () {
        function t(t) {
          return t.charAt(0).toUpperCase() + t.substring(1);
        }
        function e(t) {
          return function () {
            return this[t];
          };
        }
        var r = ["isConstructor", "isEval", "isNative", "isToplevel"],
          n = ["columnNumber", "lineNumber"],
          o = ["fileName", "functionName", "source"],
          i = r.concat(n, o, ["args"]);
        function a(e) {
          if (e) for (var r = 0; r < i.length; r++) void 0 !== e[i[r]] && this["set" + t(i[r])](e[i[r]]);
        }
        a.prototype = {
          getArgs: function () {
            return this.args;
          },
          setArgs: function (t) {
            if ("[object Array]" !== Object.prototype.toString.call(t)) throw new TypeError("Args must be an Array");
            this.args = t;
          },
          getEvalOrigin: function () {
            return this.evalOrigin;
          },
          setEvalOrigin: function (t) {
            if (t instanceof a) this.evalOrigin = t;else {
              if (!(t instanceof Object)) throw new TypeError("Eval Origin must be an Object or StackFrame");
              this.evalOrigin = new a(t);
            }
          },
          toString: function () {
            var t = this.getFileName() || "",
              e = this.getLineNumber() || "",
              r = this.getColumnNumber() || "",
              n = this.getFunctionName() || "";
            return this.getIsEval() ? t ? "[eval] (" + t + ":" + e + ":" + r + ")" : "[eval]:" + e + ":" + r : n ? n + " (" + t + ":" + e + ":" + r + ")" : t + ":" + e + ":" + r;
          }
        }, a.fromString = function (t) {
          var e = t.indexOf("("),
            r = t.lastIndexOf(")"),
            n = t.substring(0, e),
            o = t.substring(e + 1, r).split(","),
            i = t.substring(r + 1);
          if (0 === i.indexOf("@")) var s = /@(.+?)(?::(\d+))?(?::(\d+))?$/.exec(i, ""),
            u = s[1],
            c = s[2],
            l = s[3];
          return new a({
            functionName: n,
            args: o || void 0,
            fileName: u,
            lineNumber: c || void 0,
            columnNumber: l || void 0
          });
        };
        for (var s = 0; s < r.length; s++) a.prototype["get" + t(r[s])] = e(r[s]), a.prototype["set" + t(r[s])] = function (t) {
          return function (e) {
            this[t] = Boolean(e);
          };
        }(r[s]);
        for (var u = 0; u < n.length; u++) a.prototype["get" + t(n[u])] = e(n[u]), a.prototype["set" + t(n[u])] = function (t) {
          return function (e) {
            if (r = e, isNaN(parseFloat(r)) || !isFinite(r)) throw new TypeError(t + " must be a Number");
            var r;
            this[t] = Number(e);
          };
        }(n[u]);
        for (var c = 0; c < o.length; c++) a.prototype["get" + t(o[c])] = e(o[c]), a.prototype["set" + t(o[c])] = function (t) {
          return function (e) {
            this[t] = String(e);
          };
        }(o[c]);
        return a;
      }) ? n.apply(e, o) : n) || (t.exports = i);
    }();
  }, function (t, e, r) {
    "use strict";

    var n = r(0);
    function o(t, e) {
      n.isFunction(t[e]) && (t[e] = t[e].toString());
    }
    t.exports = {
      itemToPayload: function (t, e, r) {
        var o = e.payload || {};
        o.body && delete o.body;
        var i = n.merge(t.data, o);
        t._isUncaught && (i._isUncaught = !0), t._originalArgs && (i._originalArgs = t._originalArgs), r(null, i);
      },
      addTelemetryData: function (t, e, r) {
        t.telemetryEvents && n.set(t, "data.body.telemetry", t.telemetryEvents), r(null, t);
      },
      addMessageWithError: function (t, e, r) {
        if (t.message) {
          var o = "data.body.trace_chain.0",
            i = n.get(t, o);
          if (i || (o = "data.body.trace", i = n.get(t, o)), i) {
            if (!i.exception || !i.exception.description) return n.set(t, o + ".exception.description", t.message), void r(null, t);
            var a = n.get(t, o + ".extra") || {},
              s = n.merge(a, {
                message: t.message
              });
            n.set(t, o + ".extra", s);
          }
          r(null, t);
        } else r(null, t);
      },
      userTransform: function (t) {
        return function (e, r, o) {
          var i = n.merge(e);
          try {
            n.isFunction(r.transform) && r.transform(i.data, e);
          } catch (n) {
            return r.transform = null, t.error("Error while calling custom transform() function. Removing custom transform().", n), void o(null, e);
          }
          o(null, i);
        };
      },
      addConfigToPayload: function (t, e, r) {
        if (!e.sendConfig) return r(null, t);
        var o = n.get(t, "data.custom") || {};
        o._rollbarConfig = e, t.data.custom = o, r(null, t);
      },
      addConfiguredOptions: function (t, e, r) {
        var n = e._configuredOptions;
        o(n, "transform"), o(n, "checkIgnore"), o(n, "onSendCallback"), delete n.accessToken, t.data.notifier.configured_options = n, r(null, t);
      },
      addDiagnosticKeys: function (t, e, r) {
        var o = n.merge(t.notifier.client.notifier.diagnostic, t.diagnostic);
        if (n.get(t, "err._isAnonymous") && (o.is_anonymous = !0), t._isUncaught && (o.is_uncaught = t._isUncaught, delete t._isUncaught), t.err) try {
          o.raw_error = {
            message: t.err.message,
            name: t.err.name,
            constructor_name: t.err.constructor && t.err.constructor.name,
            filename: t.err.fileName,
            line: t.err.lineNumber,
            column: t.err.columnNumber,
            stack: t.err.stack
          };
        } catch (t) {
          o.raw_error = {
            failed: String(t)
          };
        }
        t.data.notifier.diagnostic = n.merge(t.data.notifier.diagnostic, o), r(null, t);
      }
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(0);
    t.exports = {
      checkIgnore: function (t, e) {
        return !n.get(e, "plugins.jquery.ignoreAjaxErrors") || !n.get(t, "body.message.extra.isAjax");
      }
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(0);
    function o(t, e, r) {
      if (!t) return !r;
      var o,
        i,
        a = t.frames;
      if (!a || 0 === a.length) return !r;
      for (var s = e.length, u = a.length, c = 0; c < u; c++) {
        if (o = a[c].filename, !n.isType(o, "string")) return !r;
        for (var l = 0; l < s; l++) if (i = e[l], new RegExp(i).test(o)) return !0;
      }
      return !1;
    }
    function i(t, e, r, i) {
      var a,
        s,
        u = !1;
      "blacklist" === r && (u = !0);
      try {
        if (a = u ? e.hostBlackList : e.hostWhiteList, s = n.get(t, "body.trace_chain") || [n.get(t, "body.trace")], !a || 0 === a.length) return !u;
        if (0 === s.length || !s[0]) return !u;
        for (var c = s.length, l = 0; l < c; l++) if (o(s[l], a, u)) return !0;
      } catch (t) {
        u ? e.hostBlackList = null : e.hostWhiteList = null;
        var p = u ? "hostBlackList" : "hostWhiteList";
        return i.error("Error while reading your configuration's " + p + " option. Removing custom " + p + ".", t), !u;
      }
      return !1;
    }
    t.exports = {
      checkLevel: function (t, e) {
        var r = t.level,
          o = n.LEVELS[r] || 0,
          i = e.reportLevel;
        return !(o < (n.LEVELS[i] || 0));
      },
      userCheckIgnore: function (t) {
        return function (e, r) {
          var o = !!e._isUncaught,
            i = e._originalArgs;
          delete e._originalArgs;
          try {
            n.isFunction(r.onSendCallback) && r.onSendCallback(o, i, e);
          } catch (e) {
            r.onSendCallback = null, t.error("Error while calling onSendCallback, removing", e);
          }
          try {
            if (n.isFunction(r.checkIgnore) && r.checkIgnore(o, i, e)) return !1;
          } catch (e) {
            r.checkIgnore = null, t.error("Error while calling custom checkIgnore(), removing", e);
          }
          return !0;
        };
      },
      urlIsNotBlacklisted: function (t) {
        return function (e, r) {
          return !i(e, r, "blacklist", t);
        };
      },
      urlIsWhitelisted: function (t) {
        return function (e, r) {
          return i(e, r, "whitelist", t);
        };
      },
      messageIsIgnored: function (t) {
        return function (e, r) {
          var o, i, a, s, u, c, l, p;
          try {
            if (u = !1, !(a = r.ignoredMessages) || 0 === a.length) return !0;
            if (c = e.body, l = n.get(c, "trace.exception.message"), p = n.get(c, "message.body"), !(o = l || p)) return !0;
            for (s = a.length, i = 0; i < s && !(u = new RegExp(a[i], "gi").test(o)); i++);
          } catch (e) {
            r.ignoredMessages = null, t.error("Error while reading your configuration's ignoredMessages option. Removing custom ignoredMessages.");
          }
          return !u;
        };
      }
    };
  }, function (t, e, r) {
    "use strict";

    var n = r(0),
      o = r(3),
      i = r(28),
      a = {
        network: !0,
        networkResponseHeaders: !1,
        networkResponseBody: !1,
        networkRequestHeaders: !1,
        networkRequestBody: !1,
        networkErrorOnHttp5xx: !1,
        networkErrorOnHttp4xx: !1,
        networkErrorOnHttp0: !1,
        log: !0,
        dom: !0,
        navigation: !0,
        connectivity: !0
      };
    function s(t, e, r, n, o) {
      var i = t[e];
      t[e] = r(i), n && n[o].push([t, e, i]);
    }
    function u(t, e) {
      for (var r; t[e].length;) (r = t[e].shift())[0][r[1]] = r[2];
    }
    function c(t, e, r, o, i) {
      this.options = t;
      var s = t.autoInstrument;
      !1 === t.enabled || !1 === s ? this.autoInstrument = {} : (n.isType(s, "object") || (s = a), this.autoInstrument = n.merge(a, s)), this.scrubTelemetryInputs = !!t.scrubTelemetryInputs, this.telemetryScrubber = t.telemetryScrubber, this.defaultValueScrubber = function (t) {
        for (var e = [], r = 0; r < t.length; ++r) e.push(new RegExp(t[r], "i"));
        return function (t) {
          var r = function (t) {
            if (!t || !t.attributes) return null;
            for (var e = t.attributes, r = 0; r < e.length; ++r) if ("name" === e[r].key) return e[r].value;
            return null;
          }(t);
          if (!r) return !1;
          for (var n = 0; n < e.length; ++n) if (e[n].test(r)) return !0;
          return !1;
        };
      }(t.scrubFields), this.telemeter = e, this.rollbar = r, this.diagnostic = r.client.notifier.diagnostic, this._window = o || {}, this._document = i || {}, this.replacements = {
        network: [],
        log: [],
        navigation: [],
        connectivity: []
      }, this.eventRemovers = {
        dom: [],
        connectivity: []
      }, this._location = this._window.location, this._lastHref = this._location && this._location.href;
    }
    c.prototype.configure = function (t) {
      this.options = n.merge(this.options, t);
      var e = t.autoInstrument,
        r = n.merge(this.autoInstrument);
      !1 === t.enabled || !1 === e ? this.autoInstrument = {} : (n.isType(e, "object") || (e = a), this.autoInstrument = n.merge(a, e)), this.instrument(r), void 0 !== t.scrubTelemetryInputs && (this.scrubTelemetryInputs = !!t.scrubTelemetryInputs), void 0 !== t.telemetryScrubber && (this.telemetryScrubber = t.telemetryScrubber);
    }, c.prototype.instrument = function (t) {
      !this.autoInstrument.network || t && t.network ? !this.autoInstrument.network && t && t.network && this.deinstrumentNetwork() : this.instrumentNetwork(), !this.autoInstrument.log || t && t.log ? !this.autoInstrument.log && t && t.log && this.deinstrumentConsole() : this.instrumentConsole(), !this.autoInstrument.dom || t && t.dom ? !this.autoInstrument.dom && t && t.dom && this.deinstrumentDom() : this.instrumentDom(), !this.autoInstrument.navigation || t && t.navigation ? !this.autoInstrument.navigation && t && t.navigation && this.deinstrumentNavigation() : this.instrumentNavigation(), !this.autoInstrument.connectivity || t && t.connectivity ? !this.autoInstrument.connectivity && t && t.connectivity && this.deinstrumentConnectivity() : this.instrumentConnectivity();
    }, c.prototype.deinstrumentNetwork = function () {
      u(this.replacements, "network");
    }, c.prototype.instrumentNetwork = function () {
      var t = this;
      function e(e, r) {
        e in r && n.isFunction(r[e]) && s(r, e, function (e) {
          return t.rollbar.wrap(e);
        });
      }
      if ("XMLHttpRequest" in this._window) {
        var r = this._window.XMLHttpRequest.prototype;
        s(r, "open", function (e) {
          return function (r, o) {
            return n.isType(o, "string") && (this.__rollbar_xhr = {
              method: r,
              url: o,
              status_code: null,
              start_time_ms: n.now(),
              end_time_ms: null
            }, t.autoInstrument.networkRequestHeaders && (this.__rollbar_xhr.request_headers = {})), e.apply(this, arguments);
          };
        }, this.replacements, "network"), s(r, "setRequestHeader", function (e) {
          return function (r, o) {
            return t.autoInstrument.networkRequestHeaders && this.__rollbar_xhr && n.isType(r, "string") && n.isType(o, "string") && (this.__rollbar_xhr.request_headers[r] = o), "content-type" === r.toLowerCase() && (this.__rollbar_xhr.request_content_type = o), e.apply(this, arguments);
          };
        }, this.replacements, "network"), s(r, "send", function (r) {
          return function (o) {
            var i = this;
            function a() {
              if (i.__rollbar_xhr && (null === i.__rollbar_xhr.status_code && (i.__rollbar_xhr.status_code = 0, t.autoInstrument.networkRequestBody && (i.__rollbar_xhr.request = o), i.__rollbar_event = t.captureNetwork(i.__rollbar_xhr, "xhr", void 0)), i.readyState < 2 && (i.__rollbar_xhr.start_time_ms = n.now()), i.readyState > 3)) {
                i.__rollbar_xhr.end_time_ms = n.now();
                var e = null;
                if (i.__rollbar_xhr.response_content_type = i.getResponseHeader("Content-Type"), t.autoInstrument.networkResponseHeaders) {
                  var r = t.autoInstrument.networkResponseHeaders;
                  e = {};
                  try {
                    var a, s;
                    if (!0 === r) {
                      var u = i.getAllResponseHeaders();
                      if (u) {
                        var c,
                          l,
                          p = u.trim().split(/[\r\n]+/);
                        for (s = 0; s < p.length; s++) a = (c = p[s].split(": ")).shift(), l = c.join(": "), e[a] = l;
                      }
                    } else for (s = 0; s < r.length; s++) e[a = r[s]] = i.getResponseHeader(a);
                  } catch (t) {}
                }
                var f = null;
                if (t.autoInstrument.networkResponseBody) try {
                  f = i.responseText;
                } catch (t) {}
                var h = null;
                (f || e) && (h = {}, f && (t.isJsonContentType(i.__rollbar_xhr.request_content_type) ? h.body = t.scrubJson(f) : h.body = f), e && (h.headers = e)), h && (i.__rollbar_xhr.response = h);
                try {
                  var d = i.status;
                  d = 1223 === d ? 204 : d, i.__rollbar_xhr.status_code = d, i.__rollbar_event.level = t.telemeter.levelFromStatus(d), t.errorOnHttpStatus(i.__rollbar_xhr);
                } catch (t) {}
              }
            }
            return e("onload", i), e("onerror", i), e("onprogress", i), "onreadystatechange" in i && n.isFunction(i.onreadystatechange) ? s(i, "onreadystatechange", function (e) {
              return t.rollbar.wrap(e, void 0, a);
            }) : i.onreadystatechange = a, i.__rollbar_xhr && t.trackHttpErrors() && (i.__rollbar_xhr.stack = new Error().stack), r.apply(this, arguments);
          };
        }, this.replacements, "network");
      }
      "fetch" in this._window && s(this._window, "fetch", function (e) {
        return function (r, o) {
          for (var i = new Array(arguments.length), a = 0, s = i.length; a < s; a++) i[a] = arguments[a];
          var u,
            c = i[0],
            l = "GET";
          n.isType(c, "string") ? u = c : c && (u = c.url, c.method && (l = c.method)), i[1] && i[1].method && (l = i[1].method);
          var p = {
            method: l,
            url: u,
            status_code: null,
            start_time_ms: n.now(),
            end_time_ms: null
          };
          if (i[1] && i[1].headers) {
            var f = new Headers(i[1].headers);
            p.request_content_type = f.get("Content-Type"), t.autoInstrument.networkRequestHeaders && (p.request_headers = t.fetchHeaders(f, t.autoInstrument.networkRequestHeaders));
          }
          return t.autoInstrument.networkRequestBody && (i[1] && i[1].body ? p.request = i[1].body : i[0] && !n.isType(i[0], "string") && i[0].body && (p.request = i[0].body)), t.captureNetwork(p, "fetch", void 0), t.trackHttpErrors() && (p.stack = new Error().stack), e.apply(this, i).then(function (e) {
            p.end_time_ms = n.now(), p.status_code = e.status, p.response_content_type = e.headers.get("Content-Type");
            var r = null;
            t.autoInstrument.networkResponseHeaders && (r = t.fetchHeaders(e.headers, t.autoInstrument.networkResponseHeaders));
            var o = null;
            return t.autoInstrument.networkResponseBody && "function" == typeof e.text && (o = e.clone().text()), (r || o) && (p.response = {}, o && ("function" == typeof o.then ? o.then(function (e) {
              t.isJsonContentType(p.response_content_type) && (p.response.body = t.scrubJson(e));
            }) : p.response.body = o), r && (p.response.headers = r)), t.errorOnHttpStatus(p), e;
          });
        };
      }, this.replacements, "network");
    }, c.prototype.captureNetwork = function (t, e, r) {
      return t.request && this.isJsonContentType(t.request_content_type) && (t.request = this.scrubJson(t.request)), this.telemeter.captureNetwork(t, e, r);
    }, c.prototype.isJsonContentType = function (t) {
      return !(!t || !t.toLowerCase().includes("json"));
    }, c.prototype.scrubJson = function (t) {
      return JSON.stringify(n.scrub(JSON.parse(t), this.options.scrubFields));
    }, c.prototype.fetchHeaders = function (t, e) {
      var r = {};
      try {
        var n;
        if (!0 === e) {
          if ("function" == typeof t.entries) for (var o = t.entries(), i = o.next(); !i.done;) r[i.value[0]] = i.value[1], i = o.next();
        } else for (n = 0; n < e.length; n++) {
          var a = e[n];
          r[a] = t.get(a);
        }
      } catch (t) {}
      return r;
    }, c.prototype.trackHttpErrors = function () {
      return this.autoInstrument.networkErrorOnHttp5xx || this.autoInstrument.networkErrorOnHttp4xx || this.autoInstrument.networkErrorOnHttp0;
    }, c.prototype.errorOnHttpStatus = function (t) {
      var e = t.status_code;
      if (e >= 500 && this.autoInstrument.networkErrorOnHttp5xx || e >= 400 && this.autoInstrument.networkErrorOnHttp4xx || 0 === e && this.autoInstrument.networkErrorOnHttp0) {
        var r = new Error("HTTP request failed with Status " + e);
        r.stack = t.stack, this.rollbar.error(r, {
          skipFrames: 1
        });
      }
    }, c.prototype.deinstrumentConsole = function () {
      if ("console" in this._window && this._window.console.log) for (var t; this.replacements.log.length;) t = this.replacements.log.shift(), this._window.console[t[0]] = t[1];
    }, c.prototype.instrumentConsole = function () {
      if ("console" in this._window && this._window.console.log) {
        var t = this,
          e = this._window.console,
          r = ["debug", "info", "warn", "error", "log"];
        try {
          for (var o = 0, i = r.length; o < i; o++) a(r[o]);
        } catch (t) {
          this.diagnostic.instrumentConsole = {
            error: t.message
          };
        }
      }
      function a(r) {
        var o = e[r],
          i = e,
          a = "warn" === r ? "warning" : r;
        e[r] = function () {
          var e = Array.prototype.slice.call(arguments),
            r = n.formatArgsAsString(e);
          t.telemeter.captureLog(r, a), o && Function.prototype.apply.call(o, i, e);
        }, t.replacements.log.push([r, o]);
      }
    }, c.prototype.deinstrumentDom = function () {
      ("addEventListener" in this._window || "attachEvent" in this._window) && this.removeListeners("dom");
    }, c.prototype.instrumentDom = function () {
      if ("addEventListener" in this._window || "attachEvent" in this._window) {
        var t = this.handleClick.bind(this),
          e = this.handleBlur.bind(this);
        this.addListener("dom", this._window, "click", "onclick", t, !0), this.addListener("dom", this._window, "blur", "onfocusout", e, !0);
      }
    }, c.prototype.handleClick = function (t) {
      try {
        var e = i.getElementFromEvent(t, this._document),
          r = e && e.tagName,
          n = i.isDescribedElement(e, "a") || i.isDescribedElement(e, "button");
        r && (n || i.isDescribedElement(e, "input", ["button", "submit"])) ? this.captureDomEvent("click", e) : i.isDescribedElement(e, "input", ["checkbox", "radio"]) && this.captureDomEvent("input", e, e.value, e.checked);
      } catch (t) {}
    }, c.prototype.handleBlur = function (t) {
      try {
        var e = i.getElementFromEvent(t, this._document);
        e && e.tagName && (i.isDescribedElement(e, "textarea") ? this.captureDomEvent("input", e, e.value) : i.isDescribedElement(e, "select") && e.options && e.options.length ? this.handleSelectInputChanged(e) : i.isDescribedElement(e, "input") && !i.isDescribedElement(e, "input", ["button", "submit", "hidden", "checkbox", "radio"]) && this.captureDomEvent("input", e, e.value));
      } catch (t) {}
    }, c.prototype.handleSelectInputChanged = function (t) {
      if (t.multiple) for (var e = 0; e < t.options.length; e++) t.options[e].selected && this.captureDomEvent("input", t, t.options[e].value);else t.selectedIndex >= 0 && t.options[t.selectedIndex] && this.captureDomEvent("input", t, t.options[t.selectedIndex].value);
    }, c.prototype.captureDomEvent = function (t, e, r, n) {
      if (void 0 !== r) if (this.scrubTelemetryInputs || "password" === i.getElementType(e)) r = "[scrubbed]";else {
        var o = i.describeElement(e);
        this.telemetryScrubber ? this.telemetryScrubber(o) && (r = "[scrubbed]") : this.defaultValueScrubber(o) && (r = "[scrubbed]");
      }
      var a = i.elementArrayToString(i.treeToArray(e));
      this.telemeter.captureDom(t, a, r, n);
    }, c.prototype.deinstrumentNavigation = function () {
      var t = this._window.chrome;
      !(t && t.app && t.app.runtime) && this._window.history && this._window.history.pushState && u(this.replacements, "navigation");
    }, c.prototype.instrumentNavigation = function () {
      var t = this._window.chrome;
      if (!(t && t.app && t.app.runtime) && this._window.history && this._window.history.pushState) {
        var e = this;
        s(this._window, "onpopstate", function (t) {
          return function () {
            var r = e._location.href;
            e.handleUrlChange(e._lastHref, r), t && t.apply(this, arguments);
          };
        }, this.replacements, "navigation"), s(this._window.history, "pushState", function (t) {
          return function () {
            var r = arguments.length > 2 ? arguments[2] : void 0;
            return r && e.handleUrlChange(e._lastHref, r + ""), t.apply(this, arguments);
          };
        }, this.replacements, "navigation");
      }
    }, c.prototype.handleUrlChange = function (t, e) {
      var r = o.parse(this._location.href),
        n = o.parse(e),
        i = o.parse(t);
      this._lastHref = e, r.protocol === n.protocol && r.host === n.host && (e = n.path + (n.hash || "")), r.protocol === i.protocol && r.host === i.host && (t = i.path + (i.hash || "")), this.telemeter.captureNavigation(t, e);
    }, c.prototype.deinstrumentConnectivity = function () {
      ("addEventListener" in this._window || "body" in this._document) && (this._window.addEventListener ? this.removeListeners("connectivity") : u(this.replacements, "connectivity"));
    }, c.prototype.instrumentConnectivity = function () {
      if ("addEventListener" in this._window || "body" in this._document) if (this._window.addEventListener) this.addListener("connectivity", this._window, "online", void 0, function () {
        this.telemeter.captureConnectivityChange("online");
      }.bind(this), !0), this.addListener("connectivity", this._window, "offline", void 0, function () {
        this.telemeter.captureConnectivityChange("offline");
      }.bind(this), !0);else {
        var t = this;
        s(this._document.body, "ononline", function (e) {
          return function () {
            t.telemeter.captureConnectivityChange("online"), e && e.apply(this, arguments);
          };
        }, this.replacements, "connectivity"), s(this._document.body, "onoffline", function (e) {
          return function () {
            t.telemeter.captureConnectivityChange("offline"), e && e.apply(this, arguments);
          };
        }, this.replacements, "connectivity");
      }
    }, c.prototype.addListener = function (t, e, r, n, o, i) {
      e.addEventListener ? (e.addEventListener(r, o, i), this.eventRemovers[t].push(function () {
        e.removeEventListener(r, o, i);
      })) : n && (e.attachEvent(n, o), this.eventRemovers[t].push(function () {
        e.detachEvent(n, o);
      }));
    }, c.prototype.removeListeners = function (t) {
      for (; this.eventRemovers[t].length;) this.eventRemovers[t].shift()();
    }, t.exports = c;
  }, function (t, e, r) {
    "use strict";

    function n(t) {
      return (t.getAttribute("type") || "").toLowerCase();
    }
    function o(t) {
      if (!t || !t.tagName) return "";
      var e = [t.tagName];
      t.id && e.push("#" + t.id), t.classes && e.push("." + t.classes.join("."));
      for (var r = 0; r < t.attributes.length; r++) e.push("[" + t.attributes[r].key + '="' + t.attributes[r].value + '"]');
      return e.join("");
    }
    function i(t) {
      if (!t || !t.tagName) return null;
      var e,
        r,
        n,
        o,
        i = {};
      i.tagName = t.tagName.toLowerCase(), t.id && (i.id = t.id), (e = t.className) && "string" == typeof e && (i.classes = e.split(/\s+/));
      var a = ["type", "name", "title", "alt"];
      for (i.attributes = [], o = 0; o < a.length; o++) r = a[o], (n = t.getAttribute(r)) && i.attributes.push({
        key: r,
        value: n
      });
      return i;
    }
    t.exports = {
      describeElement: i,
      descriptionToString: o,
      elementArrayToString: function (t) {
        for (var e, r, n = " > ".length, i = [], a = 0, s = t.length - 1; s >= 0; s--) {
          if (e = o(t[s]), r = a + i.length * n + e.length, s < t.length - 1 && r >= 83) {
            i.unshift("...");
            break;
          }
          i.unshift(e), a += e.length;
        }
        return i.join(" > ");
      },
      treeToArray: function (t) {
        for (var e, r = [], n = 0; t && n < 5 && "html" !== (e = i(t)).tagName; n++) r.unshift(e), t = t.parentNode;
        return r;
      },
      getElementFromEvent: function (t, e) {
        return t.target ? t.target : e && e.elementFromPoint ? e.elementFromPoint(t.clientX, t.clientY) : void 0;
      },
      isDescribedElement: function (t, e, r) {
        if (t.tagName.toLowerCase() !== e.toLowerCase()) return !1;
        if (!r) return !0;
        t = n(t);
        for (var o = 0; o < r.length; o++) if (r[o] === t) return !0;
        return !1;
      },
      getElementType: n
    };
  }]);
});
