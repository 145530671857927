import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { RollbarService } from 'src/app/util/rollbar';
import Rollbar from 'rollbar';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public username: string;
  public password: string;
  public error: string;

  login(): void {
    this.error = '';
    this.authService
      .login({ username: this.username, password: this.password })
      .subscribe(
        (data) => {
          this.mixpanelService.track('Action: Login Success');
          if (this.authService.redirectUrl) {
            const redirectUrl = this.authService.redirectUrl;
            this.authService.redirectUrl = null;
            this.router.navigate([redirectUrl]);
          } else {
            this.router.navigate(['dashboard']);
          }
        },
        (err) => {
          if (
            err.error?.non_field_errors &&
            err.error?.non_field_errors.length
          ) {
            this.error = err.error?.non_field_errors[0];
          }
          if (
            !this.error ||
            this.error === 'Unable to log in with provided credentials.'
          ) {
            this.error =
              'Sorry, we can\'t find an account with this username and password';
          }
          this.mixpanelService.track('Action: Login Failure');
        }
      );
  }

  forgotPassword(): void {
    this.mixpanelService.track('Dialog: Forgot Password');
    const dialogRef = this.dialog.open(ForgotPasswordDialog, {
      width: '600px',
      data: { email: '' },
    });
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    public dialog: MatDialog,
    private mixpanelService: MixpanelService
  ) {
    // If they're already logged in, send them to the dashboard
    if (this.authService.isLoggedIn()) {
      this.mixpanelService.track('Page: Login (already logged in)');
      this.router.navigate(['dashboard']);
    } else {
      this.mixpanelService.track('Page: Login');
    }
  }

  ngOnInit() { }
}

/* Password reset dialog */

export interface DialogData {
  email: string;
}

@Component({
  selector: 'forgot-password-dialog',
  templateUrl: 'forgot-password-dialog.html',
})
export class ForgotPasswordDialog {
  public message: string;
  public error: string;

  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<ForgotPasswordDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    @Inject(RollbarService) private rollbar: Rollbar
  ) { }

  resetPassword(): void {
    if (this.data.email) {
      this.authService.requestResetEmail(this.data.email).subscribe(
        (data) => {
          this.error = '';
          this.message = data.detail;
        },
        (err) => {
          this.message = '';
          this.error = 'Unable to send password reset email';
          this.rollbar.error('Could not send password reset email');
        }
      );
    }
  }
}
