<div class="main__toolbar-wrapper" ngClass.lt-md="medium-breakpoint">
  <mat-toolbar class="main__toolbar">
    <a [routerLink]="['/dashboard']" class="main__logo-link" ><img src="/assets/images/logo-header.svg"/></a>

    <div class="main__menu-wrapper flex-row">
      <a mat-menu-item [routerLink]="['/dashboard']"><mat-icon svgIcon="globe"></mat-icon><span>Countries</span></a>
      <a mat-menu-item [routerLink]="['/documents']" class="menu-documents"><mat-icon svgIcon="document"></mat-icon><span>Documents</span></a>
      <a mat-menu-item [routerLink]="['/calendar']"><mat-icon svgIcon="calendar"></mat-icon><span>Calendar</span></a>
      <a mat-menu-item [routerLink]="['/updates']" class="menu-updates"><mat-icon svgIcon="bell"></mat-icon><span>Updates</span></a>
      <app-ai-global [useTextEntry]="false"></app-ai-global>
    </div>

    <div class="main__menu-welcome-wrapper" ngClass.lt-md="medium-breakpoint">
      <a mat-flat-button iconPositionEnd [matMenuTriggerFor]="appMenu" ><span class="main__menu-welcome-text">Welcome</span><span *ngIf="currentUser$ | async as user">, {{ user?.first_name }}</span><mat-icon iconPositionEnd svgIcon="down"></mat-icon></a>
    </div>
  </mat-toolbar>
</div>
<!-- Moved outside the mat-toolbar so it doesn't mess up the spacing -->
<mat-menu #appMenu="matMenu">
  <a mat-menu-item *ngIf="isOwner()|async" [routerLink]="['/organization']">Manage Organization</a>
  <a mat-menu-item [routerLink]="['/preferences']">Update Preferences</a>
  <a *ngIf="hasSetPassword()|async" mat-menu-item [routerLink]="['/changepassword']">Change Password</a>
  <a *ngIf="!(hasSetPassword()|async)" mat-menu-item [routerLink]="['/setpassword']">Set Password</a>
  <a *ngIf="isAdmin()|async" mat-menu-item [routerLink]="['/admin']">Admin Site</a>
  <a mat-menu-item [routerLink]="['/logout']">Logout</a>
</mat-menu>

<div class="content">
  <router-outlet></router-outlet>
</div>

<mat-toolbar class="main__footer">
  <a href="https://www.globalpeoplestrategist.com/terms-of-use/" target="_blank">Terms of Service</a><span class="main__footer-divider">|</span>
  <a href="https://www.globalpeoplestrategist.com/blog/" target="_blank">Blog</a><span class="main__footer-divider">|</span>
  <app-contact-us></app-contact-us>
</mat-toolbar>
