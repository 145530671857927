<div class="documentlist__filter-wrapper">
  <form> 
    <mat-form-field appearance="outline" subscriptSizing="dynamic"
                    class="formfield__no-label formfield__no-padding documentlist__filter">
      <input matInput placeholder="Search by keyword" [formControl]="filter" (change)="updateFilter($event)" maxlength="20">
      <mat-icon matSuffix svgIcon="search"></mat-icon>
    </mat-form-field>

    <mat-form-field appearance="outline" subscriptSizing="dynamic"
                    class="formfield__no-label formfield__no-padding documentlist__country"
                    floatLabel="never">
      <mat-select [formControl]="filterCountry" (selectionChange)="updateFilter($event)" placeholder="Filter by country">
        <mat-option [value]="">All</mat-option>
        <mat-option value="global">Global</mat-option>
        <mat-option *ngFor="let country of filterCountries$|async" [value]="country.iso2">
          {{country.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" subscriptSizing="dynamic"
                    class="formfield__no-label formfield__no-padding documentlist__type"
                    floatLabel="never">
      <mat-select [formControl]="filterType" (selectionChange)="updateFilter($event)" placeholder="Filter by document type">
        <mat-option [value]="">All</mat-option>
        <mat-option *ngFor="let type of filterTypes$|async" [value]="type.slug">
        {{type.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </form>
</div>

<mat-table [dataSource]="documents$ | async">
  <ng-container matColumnDef="country">
    <mat-header-cell *matHeaderCellDef>Country</mat-header-cell>
    <mat-cell *matCellDef="let document">
      <ng-container *ngIf="document.country;else global">{{ document.country?.name }}</ng-container>
      <ng-template #global>Global</ng-template>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef>Document Type</mat-header-cell>
    <mat-cell *matCellDef="let document">{{ document.type }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="language">
    <mat-header-cell *matHeaderCellDef>Language</mat-header-cell>
    <mat-cell *matCellDef="let document">{{ document.language }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
    <mat-cell *matCellDef="let document">{{ document.name }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="published_date">
    <mat-header-cell *matHeaderCellDef>Last Reviewed On</mat-header-cell>
    <mat-cell *matCellDef="let document">{{ document.published_date|date:'MMMM yyyy' }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="downloads">
    <mat-header-cell *matHeaderCellDef>Download</mat-header-cell>
    <mat-cell *matCellDef="let document">
      <a *ngIf="document.file_pdf" mat-button href="{{ document.file_pdf }}" (click)="logDownload(document)"><mat-icon svgIcon="pdf"></mat-icon></a>
      <a *ngIf="document.file_doc" mat-button href="{{ document.file_doc }}" (click)="logDownload(document)"><mat-icon svgIcon="word"></mat-icon></a>
      <a *ngIf="document.file_xls" mat-button href="{{ document.file_xls }}" (click)="logDownload(document)"><mat-icon svgIcon="excel"></mat-icon></a>
      <a *ngIf="document.file_url" mat-button href="{{ document.file_url }}" (click)="logDownload(document)" target="_blank"><mat-icon svgIcon="link"></mat-icon></a>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="manage">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let document">
      <a *ngIf="document.is_organization_only && (canEdit$ | async)" mat-button (click)="delete(document)"><mat-icon svgIcon="trash"></mat-icon></a>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="['country', 'type', 'name', 'language', 'published_date', 'downloads', 'manage']"></mat-header-row>
  <mat-row *matRowDef="let row; columns: ['country', 'type', 'name', 'language', 'published_date', 'downloads', 'manage'];"></mat-row>

</mat-table>

<div *ngIf="loading" class="documentlist__loading">
  Loading...
</div>
<div *ngIf="(documents$ | async)?.length == 0 && !loading"  class="documentlist__noresults">
  No items match your filter selections
</div>

<mat-paginator *ngIf="(documents$ | async)?.length && !loading"
               #paginator
               class="paginator"
               (page)="handlePageEvent($event)"
               [showFirstLastButtons]="false"
               [pageSize]="20"
               [hidePageSize]="true"
               [pageIndex]="page"
               [length]="(response$ | async)?.count"
               aria-label="Select page">
</mat-paginator>


