import { Component, Inject, OnInit } from '@angular/core';
import Rollbar from 'rollbar';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MixpanelService } from 'src/app/services/mixpanel/mixpanel.service';
import { UserService } from 'src/app/services/user/user.service';
import { RollbarService } from 'src/app/util/rollbar';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  public user: User;
  public message: string;
  public error: string;

  public sendPasswordResetEmail(): void {
    this.authService.requestResetEmail(this.user.email).subscribe(
      (data) => {
        this.error = '';
        this.message = data.detail;
        this.mixpanelService.track('Action: Set Password Email Sent');
      },
      (err) => {
        this.message = '';
        this.error = 'Unable to send password reset email';
        this.rollbar.error(
          'Could not send password reset email when setting password for notifications user'
        );
      }
    );
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private mixpanelService: MixpanelService,
    @Inject(RollbarService) private rollbar: Rollbar
  ) { }

  ngOnInit(): void {
    this.userService.currentUser()?.subscribe((data) => {
      this.user = data;
    });
  }
}
