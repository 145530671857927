import {formatDate} from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {CalendarEvent} from 'src/app/models/calendar-event';
import {
  CalendarEventFormData,
  CalendarEventRequestData,
} from 'src/app/models/calendar-event-form-data';
import {CalendarEventType} from 'src/app/models/calendar-event-type';
import {CalendarFilter} from 'src/app/models/calendar-filter';
import {Country} from 'src/app/models/country';
import {environment} from 'src/environments/environment';
import {ServiceBase} from '../service-base';

export type GroupedCalendarDays = Map<string, Map<string, CalendarEvent[]>>;

export class CalendarEventDTO {
  id: number;
  date: string;
  name: string;
  description: string;
  country: Country;
  // @ts-ignore: variable name must be in lowerCamelCase, PascalCase or UPPER_CASE
  holiday_type?: 'national' | 'local';
  locality?: string;
  event_type?: CalendarEventType;
  organization: number | null;
}

@Injectable({
  providedIn: 'root',
})
export class CalendarService implements ServiceBase {
  private env = environment;
  public changes$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  /**
   * Group a list of calendar events by day and country
   * @param events List of events to be grouped
   */
  private groupCalendarEvents(events: CalendarEvent[]): GroupedCalendarDays {
    const result: GroupedCalendarDays = new Map<string,
      Map<string, CalendarEvent[]>>();

    // Sort all the events by date, country, and name
    const sortedEvents = [...events].sort(
      (a: CalendarEvent, b: CalendarEvent) => {
        let sortResult = a.date
          .startOf('day')
          .diff(b.date.startOf('day'), 'days').days;
        if (sortResult === 0) {
          sortResult = a.country.name.localeCompare(b.country.name);
          if (sortResult === 0) {
            sortResult = a.name.localeCompare(b.name);
          }
        }
        return sortResult;
      }
    );

    sortedEvents.forEach((event) => {
      // Normalized to be the start of the day, just in case that wasn't set earlier
      const d = event.date.toISODate();
      if (!result.has(d)) {
        result.set(d, new Map<string, CalendarEvent[]>());
      }
      const dayBin = result.get(d);
      if (!dayBin.has(event.country.iso2)) {
        dayBin.set(event.country.iso2, []);
      }
      const countryBin = dayBin.get(event.country.iso2);
      countryBin.push(event);
    });

    return result;
  }

  /**
   * Get events for a date range and countries, grouped so that they can be more easily displayed
   * @param startDate Start date for range of events to return (inclusive)
   * @param endDate End date for range of events to return (inclusive)
   * @param filter Filter to be applied to the list, to restrict by country and/or topic
   * @returns Nested map of events grouped and sorted by date and country
   */
  public getGroupedCalendarEvents(
    startDate: DateTime = null,
    endDate: DateTime = null,
    filter: CalendarFilter = new CalendarFilter()
  ): Observable<GroupedCalendarDays> {
    return this.getCalendarEvents(startDate, endDate, filter).pipe(
      map((events) => this.groupCalendarEvents(events))
    );
  }

  /**
   * Get all events for a specfic date range and set of countries.
   * @param startDate Start date for range of events to return (inclusive)
   * @param endDate End date for range of events to return (inclusive)
   * @param countries List of countries to get events for. If null, get for all available countries
   * @returns list of events
   */
  public getCalendarEvents(
    startDate: DateTime = null,
    endDate: DateTime = null,
    filter: CalendarFilter = new CalendarFilter()
  ): Observable<CalendarEvent[]> {
    if (!startDate || !endDate) {
      startDate = DateTime.now().startOf('month');
      endDate = DateTime.now().endOf('month');
    }
    const url = this.env.api_root.concat('headless/events/');
    let params = new HttpParams();
    params = params.set('start', startDate.toISODate());
    params = params.set('end', endDate.toISODate());
    params = params.set(
      'includeHolidays',
      filter?.showHolidays === false ? 'false' : 'true'
    );
    if (filter?.countries) {
      params = params.set(
        'countries',
        filter.countries.map((c) => c.iso2).join(',')
      );
    }
    if (filter?.topics) {
      params = params.set('topics', filter.topics.map((t) => t.slug).join(','));
    }

    const events$ = this.http.get<[CalendarEventDTO]>(url, {params}).pipe(
      shareReplay(1),
      map((events) =>
        events.map((e) => {
          return {
            id: e.id,
            name: e.name,
            description: e.description,
            country: e.country,
            date: DateTime.fromISO(e.date),
            holiday_type: e.holiday_type,
            event_type: e.event_type,
            organization: e.organization,
          };
        })
      )
    );

    return events$;
  }

  public getCalendarEventTypes(): Observable<CalendarEventType[]> {
    const url = this.env.api_root.concat('headless/eventtypes/');
    const eventTypes$ = this.http
      .get<CalendarEventType[]>(url)
      .pipe(shareReplay(1));
    return eventTypes$;
  }

  public createCalendarSubscription(
    filter: CalendarFilter
  ): Observable<string> {
    const data = {
      countries: filter.countries
        ? filter.countries.map((c) => c.iso2).join(',')
        : '',
      topics: filter.topics ? filter.topics.map((t) => t.slug).join(',') : '',
      include_holidays: filter?.showHolidays === false ? 'false' : 'true',
      name: 'GPS - ' + filter.description(),
    };
    const result = this.http.post(
      this.env.api_root.concat('calendar/subscription/create'),
      data,
      {responseType: 'text'}
    );
    return result;
  }

  public addEvent(formData: CalendarEventFormData) {
    const url = this.env.api_root.concat('headless/events/');
    return this.http.post<[CalendarEventFormData]>(
      url,
      this.prepareEventRequestData(formData)
    );
  }

  editEvent(id: number, formData: CalendarEventFormData) {
    const url = this.env.api_root.concat(`headless/events/${id}/`);
    return this.http.patch<[CalendarEventFormData]>(
      url,
      this.prepareEventRequestData(formData)
    );
  }

  prepareEventRequestData(formData: CalendarEventFormData) {

    const data: CalendarEventRequestData = {
      name: formData.name,
      date: formatDate(formData.date, 'YYYY-MM-dd', 'en-EN'),
      country: formData.country.iso2,
      description: formData.description,
      event_type: formData.custom_event_type?.length ? formData.custom_event_type : formData.event_type instanceof Object ? formData.event_type.name : formData.event_type
    };

    return data;
  }

  deleteEvent(id: number) {
    const url = this.env.api_root.concat(`headless/events/${id}`);
    return this.http.delete(url);
  }

  constructor(private http: HttpClient) {
  }

  clearCache(): void {
  }
}
