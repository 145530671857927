import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injector, Injectable } from '@angular/core';
import { RollbarService } from './rollbar';
import { AuthService } from '../services/auth/auth.service';

/* Log error messages. From https://rollbar.com/blog/angular-6-error-tracking/ */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.logout();
        }

        const rollbar = this.injector.get(RollbarService);
        rollbar.error(error);
        // let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          // errorMessage = `Error: ${error.error.message}`;
          return throwError(error);
        } else {
          // server-side error
          // errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
          return throwError(error);
        }
      })
    );
  }
}
