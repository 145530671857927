<div class="countries__content-wrapper card__wrapper">
  <div class="countries__section countries__section-main flex-fill">
    <mat-card appearance="outlined" >
      <mat-card-title class="card__title">
        <h1>All Countries</h1>
        <div>
          <a  mat-flat-button color="accent" [matMenuTriggerFor]="wantToMenu" class="countries__wantto">I want to...
            <mat-icon iconPositionEnd class="icon-white" svgIcon="down"></mat-icon>
          </a>
          <mat-menu xPosition="before"  #wantToMenu="matMenu">
            <mat-nav-list class="actionmenu">
              <a href mat-list-item [routerLink]="['/compare']">
                <span matListItemTitle>Compare Countries</span>
                <span matListItemLine>Compare regulations across countries</span>
              </a>
              <app-request-country></app-request-country>
            </mat-nav-list>
          </mat-menu>

        </div>
      </mat-card-title>
      <mat-card-content class="countries__country-list-wrapper">
        <app-all-countries></app-all-countries>
      </mat-card-content>
    </mat-card>
  </div>
</div>
