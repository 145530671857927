<div
  *ngFor="let event of events"
  class="calendarprintlistcountry__event flex-row-start"
>
  <div class="calendarprintlistcountry__countryname">{{ country?.name }}</div>
  <div
    class="calendarprintlistcountry__eventname"
    [class.is-holiday]="event.holiday_type !== null"
  >
    <span class="calendarprintlistcountry__eventname--header">{{
      event.name
    }}</span>
    <div
      class="calendarprintlistcountry__eventdescription"
      *ngIf="!event.holiday_type"
    >
      {{ event.description }}
    </div>
    <div
      class="calendarprintlistcountry__eventlabel"
      *ngIf="event.holiday_type"
    >
      Public Holiday
    </div>
    <div class="calendarprintlistcountry__eventlabel" *ngIf="event.event_type">
      {{ event.event_type.name }}
    </div>
  </div>
</div>
